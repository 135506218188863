import { MODE, RESPONSE_HEADERS } from "./ultils";

/*

export const syncSessionWithState = (
  nextUrl,
  instagramData,
  limit,
  getInstagramData,
  setInstagramData,
  setIsNextAvaiable,
  mode,
  companyId,
  applicationId,
  configurationType
) => {
  // GET THE SESSION KEY
  const sessionKey = getSessionDataPrefix(
    mode,
    companyId,
    applicationId,
    configurationType
  );

  let sessionData = JSON.parse(sessionStorage.getItem(sessionKey));

  if (limit < sessionData.length) {
    // this will have limit (10) data from total of session storage
    let limitData = sessionData.slice(0, limit);

    setInstagramData([...instagramData, ...limitData]);

    // remove the data from session which have been provided to state
    let remainingSessionData = sessionData.slice(limit);

    window.sessionStorage.setItem(
      sessionKey,
      JSON.stringify(remainingSessionData)
    );
    return instagramData;
  } else {
    setInstagramData([...instagramData, ...sessionData]);
    window.sessionStorage.setItem(sessionKey, JSON.stringify([]));
    if (nextUrl) {
      getInstagramData(nextUrl);
    } else {
      setIsNextAvaiable(false);
    }
  }
};

*/

export const syncSessionWithState = () =>  {
  let currentCount = 0;
  let images = [];
  
  return (
    nextUrl,
    instagramData,
    limit,
    getInstagramData,
    setInstagramData,
    setIsNextAvaiable,
    mode,
    companyId,
    applicationId,
    configurationType,
  ) => {

    if (!images.length) {
      // GET THE SESSION KEY
      const sessionKey = getSessionDataPrefix(
        mode,
        companyId,
        applicationId,
        configurationType
      );
    
      let sessionData = JSON.parse(sessionStorage.getItem(sessionKey));
      images = sessionData;
    }
    
  
    const range = currentCount + limit;
    if (currentCount + limit < images.length) {
      // this will have limit (10) data from total of session storage
      let limitData = images.slice(currentCount, range);
  
      setInstagramData([...instagramData, ...limitData]);

      currentCount = range;

      return instagramData;
    } else {
      const remainingImages = images.slice(currentCount);
      setInstagramData([...instagramData, ...remainingImages]);
  
      if (nextUrl) {
        getInstagramData(nextUrl);
        /*
          TODO: have to remove when next 100 Data from instagram API is integrated
        */
        setIsNextAvaiable(false);
      } else {
        setIsNextAvaiable(false);
      }
    }
  };
}

export const getSessionDataPrefix = (
  mode,
  companyId = null,
  applicationId = null,
  configurationType
) => {
  if (mode === MODE[0]) {
    // get company id and aplication id from session storage
    const cid = localStorage.getItem(RESPONSE_HEADERS.COMPANY_ID);
    const aid = localStorage.getItem(RESPONSE_HEADERS.APPLICATION_ID);
    return `${cid}_${aid}_${configurationType}`;
  } else if (mode === MODE[1]) {
    // use company id and application id from function parameters
    return `${companyId}_${applicationId}_${configurationType}`;
  }
};

export function delayBy(value = 5000, func) {
  return new Promise((resolve) => {
      setTimeout(() => {
          resolve(func.call())
      }, value)
  })
}
