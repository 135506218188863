var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{attrs:{"data-testid":_vm.dataTestIdComputed}},[_vm._l((_vm.groupImages),function(item,index){return _c('div',{key:index,staticClass:"container",style:({
      marginBottom: _vm.getSpacing + 'px',
    })},[_c('div',{staticClass:"grid-container",style:({
        marginBottom: _vm.getSpacing + 'px',
        gap: _vm.getSpacing + 'px',
        gridTemplateColumns: ("repeat(" + _vm.getGridTemplateColumns + ", 1fr)"),
        gridTemplateRows: ("repeat(" + _vm.getGridTemplateRows + ", 1fr)"),
      })},_vm._l((item),function(image,index){return _c('div',{key:index,staticClass:"d-flex"},[_c('SocialCard',{attrs:{"item":image,"index":image.actualIndex,"hoverEffects":_vm.options.data.hoverEffects,"mode":_vm.options.data.mode},on:{"handleOnClickAction":_vm.handleOnClickAction}})],1)}),0)])}),_c('div',{staticClass:"dis-flex hrz-center"},[_c('LoadMoreButton',{attrs:{"isNextAvaiable":_vm.isNextAvaiable},on:{"handleClick":_vm.handleOnNext}})],1),_c('modalContainer',{ref:"modal"},[[_c('modalContent',{attrs:{"currentImageObject":_vm.imageObject,"modalCurrentIndex":_vm.imageIndex,"allImages":this.instagramData,"mode":this.options.data.mode,"application_id":this.options.data.application_id,"company_id":this.options.data.company_id},on:{"prevImage":function($event){return _vm.previtem($event)},"nextImage":function($event){return _vm.nextitem($event)},"handleCloseModal":function($event){return _vm.handleCloseModal()}}})]],2)],2)}
var staticRenderFns = []

export { render, staticRenderFns }