<template>
  <transition name="fade">
    <div class="modal" v-show="show">
      <div class="modal__backdrop" @click.stop="closeModal()" />
      <div class="modal__dialog">
        <div class="modal__body">
          <div v-if="this.isMobile" class="modal__close-btn-container" @click="closeModal()">
            <img src="../../assets/icon/close_btn.svg" alt="close button" />
          </div>
          <slot />
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
export default {
  name: "modal",
  props:{
    isMobile:{
      default:false
    }
  },
  data() {
    return {
      show: false,
    };
  },
  methods: {
    closeModal() {
      this.show = false;
      document.querySelector("body").classList.remove("no-scroll");
    },
    openModal() {
      this.show = true;
      document.querySelector("body").classList.add("no-scroll");
    },
  },
};
</script>

<style lang="scss" scoped>
.modal {
  overflow-x: hidden;
  overflow-y: auto;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1000;
  display: flex;
  align-items: center;
  height: 100vh;
  width: 100vw;

  &__backdrop {
    background-color: rgba(51, 51, 51, 0.7);
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    opacity: 0.9;
  }
  &__dialog {
    position: relative;
    margin: auto;
    display: flex;
    flex-direction: column;
    border-radius: 5px;
    width: 80%;
    max-width: 960px;
    max-height: 85%;
    height: 85%;
    justify-content: center;
    @media screen and (max-width: 480px) {
      min-width: 100vw;
      width: 100vw;
      min-height: 100vh;
      height: 100vh;
    }
  }
  &__body {
    overflow: auto;
    display: flex;
    flex-direction: column;
    align-items: stretch;
  }
  
  &__close-btn-container{
    position: absolute;
    right: 16px;
    top: 14px;
    background: white;
    border-radius: 50%;
    width: 25px;
    height: 25px;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 999999;
    cursor: pointer;

    img{
      width: 10px;
      height: 10px;
    }
  }
}
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.2s;
}
.fade-enter,
.fade-leave-to {
  opacity: 0;
}
</style>
