<template>
  <div :data-testid="dataTestIdComputed">
    <MainHeading
      :allignmentType="allignmentType"
      :value="getHeading"
      :show="showHeading"
      :dataTestId="dataTestIdComputed"
    />

    <SubHeading
      :allignmentType="allignmentType"
      :value="getSubHeading"
      :show="showSubHeading"
      :dataTestId="dataTestIdComputed"
    />

    <component v-bind:is="getCorrectResponsiveLayout" :options="options" :dataTestId="dataTestIdComputed"/>
  </div>
</template>

<script>
import GridSocialArrangement from "./grid/grid-social-arrangement.vue";
import SocialArrangement from "./carousel/social-arrangement.vue";
import GridSocialArrangementResponsive from "./grid/grid-social-arrangement-responsive.vue";
import SocialArrangementResponsive from "./carousel/social-arrangement-responsive.vue";
import SubHeading from "../sub-heading.vue";
import MainHeading from "../main-heading.vue";
import { UNIQUE_IDENTIFIER_TYPES, generateDataTestId } from '../../constant/testingConstants';

export default {
  name: "social-arrangement-main",
  title: "Social arrangement main",
  props: {
    options: { default: {} },
    dataTestId:{ default : null}
  },
  components: {
    GridSocialArrangement,
    SocialArrangement,
    GridSocialArrangementResponsive,
    SocialArrangementResponsive,
    SubHeading,
    MainHeading,
  },
  data() {
    return {
      component: {
        GRID: "GridSocialArrangement",
        CAROUSEL: "SocialArrangement",
      },
      componentResponsive: {
        GRID: "GridSocialArrangementResponsive",
        CAROUSEL: "SocialArrangementResponsive",
      },
    };
  },
  computed: {
    allignmentType() {
      return this.options.data.globalSettings.title.allignment.value;
    },
    showHeading() {
      return this.options.data.globalSettings.title.heading.show;
    },
    showSubHeading() {
      return (
        this.showHeading &&
        this.options.data.globalSettings.title.subHeading.show
      );
    },
    getHeading() {
      return this.options.data.globalSettings.title.heading.content;
    },
    getSubHeading() {
      return this.options.data.globalSettings.title.subHeading.content;
    },
    getActiveLayout() {
      return this.options.data.globalSettings.layoutTemplate.value;
    },
    getActiveLayoutResponsive() {
      return this.options.data.globalSettings.layoutTemplate.value;
    },
    getCorrectResponsiveLayout() {
      if (this.options.data.isMobile)
        return this.componentResponsive[this.getActiveLayout];
      return this.component[this.getActiveLayout];
    },
    dataTestIdComputed(){
      return generateDataTestId(this.dataTestId,`Social_${UNIQUE_IDENTIFIER_TYPES.ARRANGEMENT}`);
    }
  },
};
</script>

<style scoped></style>
