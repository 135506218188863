<template>
  <div v-if="config" class="default-margin font-body" :data-testid="dataTestIdComputed">
    <component v-bind:is="componentMain" :options="getOptions" :dataTestId="dataTestIdComputed"/>
  </div>
</template>

<script>
import ReelArrangementMain from "./components/reel/reel-arrangement-main.vue";
import { MODE } from "./services/ultils";
import MainServices from "./services/instagram.service";
import { generateDataTestId } from "./constant/testingConstants";

export default {
  components: {
    ReelArrangementMain,
  },
  props:{
    dataTestId:{
      default: null
    }
  },
  computed: {
    componentMain() {
      return this.component["REEL_ARRANGEMENT"];
    },
    getOptions() {
      return {
        data: {
          ...this.config.data,
          isMobile: this.isMobile,
          mode: this.mode,
          company_id: null,
          application_id: null,
        },
      };
    },
    dataTestIdComputed(){
      return generateDataTestId(this.dataTestId,"Storefront");
    }
  },

  data() {
    return {
      config: false,
      isMobile: null,
      mode: MODE[0],
      component: {
        REEL_ARRANGEMENT: "ReelArrangementMain",
      },
    };
  },
  methods: {
    getIsMobile() {
      // TO BE USED WHEN MOBILE COMPONENT WILL BE READY
      if (
        navigator.userAgent.match(/Android/i) ||
        navigator.userAgent.match(/webOS/i) ||
        navigator.userAgent.match(/iPhone/i) ||
        navigator.userAgent.match(/iPad/i) ||
        navigator.userAgent.match(/iPod/i) ||
        navigator.userAgent.match(/BlackBerry/i) ||
        navigator.userAgent.match(/Windows Phone/i)
      ) {
        return true;
      }
      return false;
    },
    async getConfig() {
      const res = await MainServices.getConfiguration();
      return res.data;
    },
  },
  async mounted() {
    this.isMobile = this.getIsMobile();
    // GET CONFIG FOR PARTICULAR COMPANY_ID AND APPLICATION_ID
    const data = await this.getConfig();
    this.config = {
      data: data.reels_configs,
    }; // USING reels_configs data for REELS BINDINGS
  },
};
</script>

<style scoped>
.default-margin {
  margin: 24px;
}
</style>
