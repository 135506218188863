<template>
  <div
    class="main-container"
    v-if="allImages.length > 0 && currentImageObject !== null"
  >
    <!-- previous next button -->
    <div v-if="allImages.length > 1">
      <div
        class="prev-btn-container"
        v-if="modalCurrentIndex > 0"
        @click.self="prevItem()"
      ></div>
      <div
        class="next-btn-container"
        v-if="modalCurrentIndex < allImages.length - 1"
        @click.self="nextItem()"
      ></div>
    </div>
    <!-- left panel layout -->
    <div class="left-panel-container">
      <!-- conditionally render img ,video and slider -->
      <div v-if="getMediaType === 'IMAGE'" class="d-flex">
        <ImageComponent :mediaUrl="getMediaUrl" />
      </div>
      <div v-if="getMediaType === 'VIDEO'" class="d-flex">
        <VideoComponent :mediaUrl="getMediaUrl" />
      </div>
      <div v-if="getMediaType === 'CAROUSEL_ALBUM'">
        <!-- INSERT SLIDER HERE -->
        <albumSliderComponent :albumItem="this.currentImageObject" :options="options" />
      </div>
    </div>

    <!-- right-panel-layout -->
    <div class="right-panel-container">
      <!-- Right Panel Title -->
      <div class="title-bar">
        <div class="instagram-icon-container">
          <img
            src="../../assets/icon/social_card_profile.svg"
            alt="instagram_icon"
          />
          <span>{{ getUserName }}</span>
        </div>
      </div>

      <div class="close-btn-container" @click="closeModalView()">
        <img src="../../assets/icon/close_btn.svg" alt="close button" />
      </div>

      <div class="right-panel-content">
        <!-- Caption container -->
        <div
          class="caption-container"
          v-if="this.currentImageObject && this.currentImageObject.caption"
        >
          <p class="caption-item" v-html="caption"></p>
        </div>
      </div>

      <!-- Right panel fotter -->
      <div class="right-panel-footer">
        <div class="share-media-container">
          <span>Share</span>
          <a
            :href="`https://www.pinterest.com/pin/create/button/?url=${url}`"
            target="_blank"
          >
            <svg
              width="14"
              height="14"
              viewBox="0 0 14 14"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              class="share-icon"
            >
              <path
                d="M7.0001 0.599609C3.4657 0.599609 0.600098 3.46521 0.600098 6.99961C0.600098 9.71108 2.28703 12.0268 4.66783 12.9591C4.61183 12.4529 4.56117 11.6759 4.69023 11.1233C4.8065 10.6241 5.44063 7.94201 5.44063 7.94201C5.44063 7.94201 5.24917 7.55854 5.24917 6.99214C5.24917 6.10201 5.7649 5.43801 6.40703 5.43801C6.95317 5.43801 7.21663 5.84814 7.21663 6.33934C7.21663 6.88868 6.8673 7.70948 6.6865 8.47001C6.53557 9.10734 7.00597 9.62681 7.63423 9.62681C8.77183 9.62681 9.64597 8.42734 9.64597 6.69614C9.64597 5.16388 8.54517 4.09241 6.9729 4.09241C5.1521 4.09241 4.0833 5.45828 4.0833 6.87001C4.0833 7.41988 4.29503 8.00975 4.55957 8.33081C4.61183 8.39428 4.6193 8.44975 4.60383 8.51428C4.5553 8.71641 4.44757 9.15108 4.42623 9.24014C4.3985 9.35748 4.33343 9.38201 4.21237 9.32548C3.4129 8.95321 2.91316 7.78468 2.91316 6.84601C2.91316 4.82734 4.37983 2.97294 7.14197 2.97294C9.36223 2.97294 11.0876 4.55481 11.0876 6.66948C11.0876 8.87535 9.69663 10.6503 7.7665 10.6503C7.11797 10.6503 6.50837 10.3132 6.29983 9.91535C6.29983 9.91535 5.97877 11.1372 5.9009 11.4369C5.75637 11.9927 5.3665 12.6897 5.10517 13.1148C5.7041 13.2993 6.34037 13.3996 7.0001 13.3996C10.5345 13.3996 13.4001 10.534 13.4001 6.99961C13.4001 3.46521 10.5345 0.599609 7.0001 0.599609Z"
              />
            </svg>
          </a>
          <a
            :href="`https://www.facebook.com/sharer/sharer.php?u=${url}`"
            target="_blank"
          >
            <svg
              width="14"
              height="14"
              viewBox="0 0 14 14"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              class="share-icon"
            >
              <path
                d="M7.0001 0.599609C3.4657 0.599609 0.600098 3.46521 0.600098 6.99961C0.600098 10.2081 2.96383 12.8577 6.0433 13.3207V8.69561H4.45983V7.01348H6.0433V5.89401C6.0433 4.04068 6.94623 3.22734 8.4865 3.22734C9.2241 3.22734 9.6145 3.28228 9.79903 3.30681V4.77508H8.74836C8.0945 4.77508 7.86623 5.39534 7.86623 6.09401V7.01348H9.7825L9.52276 8.69561H7.86623V13.334C10.99 12.9105 13.4001 10.2396 13.4001 6.99961C13.4001 3.46521 10.5345 0.599609 7.0001 0.599609Z"
              />
            </svg>
          </a>
          <a
            :href="`https://twitter.com/intent/tweet?text=${url}`"
            target="_blank"
          >
            <svg
              width="14"
              height="12"
              viewBox="0 0 14 12"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              class="share-icon"
            >
              <path
                d="M13.9331 1.70008C13.4227 1.92674 12.8745 2.07928 12.299 2.14808C12.8867 1.79608 13.3374 1.23874 13.5497 0.574211C13.0003 0.900078 12.3913 1.13688 11.7427 1.26488C11.2238 0.711811 10.4846 0.366211 9.66645 0.366211C8.09525 0.366211 6.82165 1.64034 6.82165 3.21101C6.82165 3.43394 6.84725 3.65154 6.89525 3.85901C4.53098 3.74061 2.43498 2.60781 1.03125 0.886211C0.786985 1.30648 0.646718 1.79501 0.646718 2.31714C0.646718 3.30381 1.14858 4.17474 1.91178 4.68461C1.44565 4.66968 1.00672 4.54168 0.623251 4.32888C0.623251 4.34114 0.623251 4.35234 0.623251 4.36461C0.623251 5.74328 1.60352 6.89314 2.90538 7.15394C2.66698 7.21901 2.41525 7.25368 2.15552 7.25368C1.97258 7.25368 1.79392 7.23554 1.62058 7.20301C1.98272 8.33314 3.03338 9.15608 4.27818 9.17901C3.30485 9.94221 2.07818 10.3971 0.744851 10.3971C0.515518 10.3971 0.288851 10.3838 0.065918 10.3571C1.32512 11.1641 2.82005 11.635 4.42698 11.635C9.66005 11.635 12.5209 7.30008 12.5209 3.54061C12.5209 3.41741 12.5182 3.29474 12.5129 3.17261C13.0691 2.77101 13.5518 2.27021 13.9331 1.70008Z"
              />
            </svg>
          </a>
        </div>
        <div class="timestamp-container">
          Posted {{ getDatepost(this.currentImageObject.timestamp) }} ago via
          instagram
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ImageComponent from "./image-component.vue";
import VideoComponent from "./video-component.vue";
import albumSliderComponent from "./album-slider-component.vue";
import { datedifference, extended_split } from "../../services/ultils";

export default {
  name: "modal-content",
  components: { ImageComponent, VideoComponent, albumSliderComponent },
  props: {
    currentImageObject: { default: null },
    modalCurrentIndex: { default: 0 },
    allImages: { default: [] },
    prevImage: { default: null },
    nextImage: { default: null },
    handleCloseModal: { default: null },
    mode: { default: null },
    application_id: { default: null },
    company_id: { default: null },
  },
  data() {
    return {
      caption: null,
      url: window.location.hostname,
      options:{},
    };
  },
  mounted() {
    this.options = {
      data:{
        mode:this.mode,
        application_id:this.application_id,
        company_id:this.company_id
      }
    };

    this.getImagecaption();
  },
  computed: {
    getUserName() {
      return this.currentImageObject.username;
    },
    getMediaUrl() {
      return this.currentImageObject.media_url;
    },
    getMediaType() {
      return this.currentImageObject.media_type;
    },
  },
  watch: {
    currentImageObject: function () {
      this.getImagecaption();
    },
  },
  methods: {
    getImagecaption() {
      let mediaCaption;
      if (this.currentImageObject)
        mediaCaption = this.currentImageObject.caption;
      let instagramCaption;
      if (mediaCaption) {
        instagramCaption = extended_split(
          mediaCaption.replace(/(?:\r\n|\r|\n)/g, "<br />"),
          "#",
          2
        );
      }
      if (instagramCaption) {
        if (instagramCaption[0] && instagramCaption[1]) {
          this.caption =
            "<span style='font-weight:bold'>@" +
            this.getUserName +
            "</span>" +
            " " +
            instagramCaption[0] +
            "<br/><span style='font-weight:bold'>" +
            "#" +
            instagramCaption[1] +
            "</span>";
        } else if (instagramCaption[0]) {
          this.caption =
            "<span style='font-weight:bold'>@" +
            this.getUserName +
            "</span>" +
            " " +
            instagramCaption[0];
        } else {
          this.caption =
            "<span style='font-weight:bold'>@" +
            this.getUserName +
            "</span>" +
            " " +
            "<span style='font-weight:bold'>#" +
            instagramCaption[1] +
            "</span>";
        }
      }
    },
    getDatepost(item) {
      let dateTime = new Date(item).getTime();
      let posted = datedifference(dateTime);
      return posted;
    },
    closeModalView() {
      this.$emit("handleCloseModal");
    },
    prevItem() {
      this.$emit("prevImage");
    },
    nextItem() {
      this.$emit("nextImage");
    },
  },
};
</script>

<style lang="scss" scoped>
.main-container {
  height: 100%;
  display: flex;
}

.prev-btn-container {
  position: absolute;
  left: -35px;
  top: 50%;
  background: transparent;
  border: none;
  width: 16px;
  height: 26px;
  background-image: url(../../assets/icon/previous_btn.svg);
  background-repeat: no-repeat;
  cursor: pointer;
}

.next-btn-container {
  position: absolute;
  right: -32px;
  top: 50%;
  background: transparent;
  border: none;
  width: 16px;
  height: 26px;
  background-image: url(../../assets/icon/next_btn.svg);
  background-repeat: no-repeat;
  cursor: pointer;
}

.left-panel-container {
  width: 50%;
  flex: 1;
  background: black;
  > div {
    width: 100%;
    height: 100%;
  }
  .d-flex {
    display: flex;
    align-items: center;
  }
}

.right-panel-container {
  width: 50%;
  background: var(--dialogBackground, #ffffff);
  box-sizing: border-box;
  padding: 16px;
  position: relative;
  display: flex;
  flex-direction: column;
  flex: 1;
  .title-bar {
    display: flex;
    margin-bottom: 16px;
    justify-content: center;
    align-items: center;
    .instagram-icon-container {
      border-radius: 50%;
      display: flex;
      align-items: center;
      width: 100%;
      img {
        object-fit: contain;
        margin-right: 5px;
        widows: 20px;
        height: 20px;
      }
      span {
        line-height: 130%;
        width: 80%;
        word-break: break-word;

        /* font styling to be fetched from storefront */
        /* fallback color as present in preview */
        font-family: unset;
        color: var(--textHeading, #41434c);

        /* Using Storefront font as per design */
        font-weight: 600;
        font-size: 20px;

        @media all and (max-width: 767px) {
          font-size: 12px;
        }
      }
    }
  }

  .close-btn-container {
    position: absolute;
    right: 19px;
    width: 14px;
    height: 14px;
    cursor: pointer;

    @media all and (max-width: 767px) {
      width: 10px;
      height: 10px;
    }

    img {
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
  }
  .right-panel-content {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    flex-grow: 1;
    overflow-y: scroll;
    margin-bottom: 16px;

    .caption-container {
      line-height: 150%;
      /* font styling to be fetched from storefront */
      /* fallback color as present in preview */
      font-family: unset;
      color: var(--textBody, #5c5c5c);

      /* Using Storefront font as per design */
      font-weight: 400;
      font-size: 14px;
      .caption-item {
        margin: 0;
      }
    }
  }

  .right-panel-footer {
    .share-media-container {
      display: flex;
      flex-direction: row;
      align-items: center;
      span {
        margin-right: 10px;
        line-height: 160%;
        /* font styling to be fetched from storefront */
        /* fallback color as present in preview */
        font-family: unset;
        color: var(--textBody, #1f1f1f);

        /* Using Storefront font as per design */
        font-weight: 400;
        font-size: 12px;
      }
      a {
        margin-right: 8px;
        .share-icon {
          path {
            fill: var(--textBody, #1f1f1f);
          }
        }
      }
    }

    .timestamp-container {
      margin-top: 10px;
      line-height: 160%;
      /* font styling to be fetched from storefront */
      /* fallback color as present in preview */
      font-family: unset;
      color: var(--textBody, #1f1f1f);

      /* Using Storefront font as per design */
      font-weight: 400;
      font-size: 12px;
    }
  }
}
</style>
