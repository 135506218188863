<template>
  <div :data-testid="dataTestIdComputed">
    <div class="parent-grid-container" :style="{ gap: getSpacing + 'px' }">
      <div
        class="grid-container"
        v-for="(item, index) in groupImages"
        :key="index"
        :style="{ gap: getSpacing + 'px' }"
      >
        <ImageWrapper
          :hoverEffects="options.data.hoverEffects"
          :caption="item.image.caption"
          @onClickPopup="
            handleOnClickAction(item.image, item.image.actualIndex)
          "
        >
          <SingleMediaElement
            :mediaType="item.image.media_type"
            :imageObject="item.image"
          />
        </ImageWrapper>

        <div class="grid-of-two" :style="{ gap: getSpacing + 'px' }">
          <ImageWrapper
            v-for="(image, i) in item.images"
            :hoverEffects="options.data.hoverEffects"
            :caption="image.image.caption"
            :key="i"
            @onClickPopup="
              handleOnClickAction(image.image, image.image.actualIndex)
            "
          >
            <SingleMediaElement
              :mediaType="image.image.media_type"
              :imageObject="image.image"
            />
          </ImageWrapper>
        </div>

        <ImageWrapper
          :hoverEffects="options.data.hoverEffects"
          :caption="item.rightImg.caption"
          @onClickPopup="
            handleOnClickAction(item.rightImg, item.rightImg.actualIndex)
          "
        >
          <SingleMediaElement
            :mediaType="item.rightImg.media_type"
            :imageObject="item.rightImg"
          />
        </ImageWrapper>
      </div>
    </div>
    <div class="dis-flex hrz-center">
      <LoadMoreButton
        :isNextAvaiable="isNextAvaiable"
        @handleClick="handleOnNext"
      />
    </div>
    <modalContainer ref="modal">
      <template>
        <modalContent
          :currentImageObject="imageObject"
          :modalCurrentIndex="imageIndex"
          :allImages="this.instagramData"
          :mode="this.options.data.mode"
          :application_id="this.options.data.application_id"
          :company_id="this.options.data.company_id"
          @prevImage="previtem($event)"
          @nextImage="nextitem($event)"
          @handleCloseModal="handleCloseModal()"
        />
      </template>
    </modalContainer>
  </div>
</template>

<script>
import ImageWrapper from "../../image-wrapper.vue";
import InstagramService from "../../../services/instagram.service";
import {
  getSessionDataPrefix,
  syncSessionWithState,
} from "../../../services/helper";
import modalContainer from "../../modal/modal-container.vue";
import modalContent from "../../modal/modal-content.vue";
import LoadMoreButton from "../../load-more-button.vue";
import SingleMediaElement from "../../single-media-element.vue";
import {
  CLICK_SETTING,
  CONFIGURATION_TYPE,
  MEDIA_TYPE,
  SESSION_DATA_LIMIT_DESKTOP,
} from "../../../services/ultils";
import { UNIQUE_IDENTIFIER_TYPES, generateDataTestId } from "../../../constant/testingConstants";

export default {
  name: "gallery-arrangement",
  title: "Grid random layout",
  props: {
    options: { default: {} },
    dataTestId:{ default : null}
  },

  components: {
    ImageWrapper,
    modalContainer,
    modalContent,
    LoadMoreButton,
    SingleMediaElement,
  },

  computed: {
    getSpacing() {
      return this.options.data.globalSettings.spacing.value;
    },
    groupImages() {
      const result = [];
      let imageGroupInstaData = this.instagramData;

      let currentGroup = {
        image: { ...imageGroupInstaData[0], actualIndex: 0 },
        images: [],
        rightImg: {},
      };

      for (let i = 1; i < imageGroupInstaData.length; i++) {
        if (i % 4 === 0) {
          result.push(currentGroup);
          currentGroup = {
            ...currentGroup,
            image: { ...imageGroupInstaData[i], actualIndex: i },
            images: [],
          };
        } else if (i % 4 === 1 || i % 4 === 2) {
          currentGroup.images.push({
            image: { ...imageGroupInstaData[i], actualIndex: i },
          });
        } else {
          currentGroup = {
            ...currentGroup,
            rightImg: { ...imageGroupInstaData[i], actualIndex: i },
          };
        }
      }

      result.push(currentGroup);
      return result;
    },
    dataTestIdComputed(){
      return generateDataTestId(this.dataTestId,`${UNIQUE_IDENTIFIER_TYPES.DESKTOP}_${UNIQUE_IDENTIFIER_TYPES.GRID}`);
    }
  },

  data() {
    return {
      limit: 4,
      isNextAvaiable: true,
      instagramData: [],
      imageObject: null,
      imageIndex: 0,
      syncSession: null,
    };
  },

  methods: {
    setInstagramData(value) {
      this.instagramData = value;
    },
    setIsNextAvaiable(value) {
      this.isNextAvaiable = value;
    },
    handleOnClickAction(currentImageObj, currentImageIndex) {
      const onClickValue =
        this.options.data.onClickActions.onClickConfiguration.value;
      if (onClickValue === CLICK_SETTING.OPEN_IN_POPUP) {
        this.$refs.modal.openModal();
        this.imageObject = currentImageObj;
        this.imageIndex = currentImageIndex;
      } else if (onClickValue === CLICK_SETTING.OPEN_IN_INSTAGRAM) {
        window.open(currentImageObj?.permalink, "_blank");
      }
    },
    previtem() {
      if (this.imageIndex > 0) {
        this.imageIndex -= 1;
      }
      if (this.instagramData[this.imageIndex]) {
        this.imageObject = this.instagramData[this.imageIndex];
      }
    },
    nextitem() {
      if (this.imageIndex >= this.instagramData.length) {
        this.imageIndex = this.instagramData.length;
      } else {
        this.imageIndex += 1;
      }
      if (this.instagramData[this.imageIndex]) {
        this.imageObject = this.instagramData[this.imageIndex];
      }
    },
    handleCloseModal() {
      this.$refs.modal.closeModal();
    },
    handleOnNext() {
      if (this.isNextAvaiable) {
        this.syncSession(
          this.nextUrl,
          this.instagramData,
          this.limit,
          this.getInstagramData,
          this.setInstagramData,
          this.setIsNextAvaiable,
          this.options.data.mode,
          this.options.data.company_id,
          this.options.data.application_id,
          CONFIGURATION_TYPE.POST_VIDEO
        );
      }
    },

    async getInstagramData(nextUrl) {
      let postListResponse;
      try {
        if (nextUrl) {
          console.log("get next data from url in params");
        } else {
          postListResponse = await InstagramService.getPostList(
            SESSION_DATA_LIMIT_DESKTOP,
            this.options.data.mode,
            this.options.data.company_id,
            this.options.data.application_id
          );
        }
      } catch (error) {
        return error;
      }
      let sessionData = postListResponse.data.data;
      if (!this.options.data.globalSettings.includeVideos.value) {
        sessionData = sessionData.filter(
          (x) =>
            x.media_type === MEDIA_TYPE.IMAGE ||
            x.media_type === MEDIA_TYPE.CAROUSEL_ALBUM
        );
      }

      const sessionKey = getSessionDataPrefix(
        this.options.data.mode,
        this.options.data.company_id,
        this.options.data.application_id,
        CONFIGURATION_TYPE.POST_VIDEO
      );
      // get the Session data prefix to be used to store data
      window.sessionStorage.setItem(sessionKey, JSON.stringify(sessionData));

      window.sessionStorage.setItem(
        "nextUrl",
        JSON.stringify(this.nextUrl ? this.nextUrl : false)
      );
    },
  },
  async mounted() {
    // set data on session storage 100 saved on session
    await this.getInstagramData();
    this.syncSession = syncSessionWithState();
    this.syncSession(
      this.nextUrl,
      this.instagramData,
      this.limit,
      this.getInstagramData,
      this.setInstagramData,
      this.setIsNextAvaiable,
      this.options.data.mode,
      this.options.data.company_id,
      this.options.data.application_id,
      CONFIGURATION_TYPE.POST_VIDEO
    );
  },
};
</script>

<style scoped>
.dis-flex {
  display: flex;
}

.hrz-center {
  justify-content: center;
}

.parent-grid-container {
  gap: 10px;
  display: grid;
}

.grid-container {
  display: grid;
  grid-template-columns: 2fr 1fr 2fr;
}

.grid-of-two {
  display: grid;
  grid-template-columns: auto;
  grid-template-rows: 1fr 1fr;
}
</style>
