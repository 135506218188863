<template>
  <div :data-testid="dataTestIdComputed">
    <div
      class="container"
      :style="{ marginBottom: getSpacing + 'px' }"
      v-for="(item, index) in groupImages"
      :key="index"
    >
      <!-- EACH GRID -->
      <div
        class="grid-container"
        :style="{
          gap: getSpacing + 'px',
          gridTemplateColumns: `repeat(4, 1fr)`,
          gridTemplateRows: `repeat(1, 1fr)`,
        }"
      >
        <div
          v-for="(image, index) in item"
          :key="index"
          class="pos-rel"
          @click="handleOnClickAction(image, image.actualIndex)"
        >
          <img :src="reelPlay" class="reel-play-overlay" alt="" />
          <video
            loop
            class="video-item"
            :src="image.media_url"
            autoplay
            muted
            playsinline
            webkit-playsinline="true"
          ></video>
        </div>
      </div>
    </div>
    <div class="dis-flex hrz-center">
      <LoadMoreButton
        :isNextAvaiable="isNextAvaiable"
        @handleClick="handleOnNext"
      />
    </div>
    <modalContainer ref="modal">
      <template>
        <modalContent
          :currentImageObject="imageObject"
          :modalCurrentIndex="imageIndex"
          :allImages="this.instagramData"
          :mode="this.options.data.mode"
          :application_id="this.options.data.application_id"
          :company_id="this.options.data.company_id"
          @prevImage="previtem($event)"
          @nextImage="nextitem($event)"
          @handleCloseModal="handleCloseModal()"
        />
      </template>
    </modalContainer>
  </div>
</template>

<script>
import InstagramService from "../../../services/instagram.service";
import {
  getSessionDataPrefix,
  syncSessionWithState,
} from "../../../services/helper";
import modalContainer from "../../modal/modal-container.vue";
import modalContent from "../../modal/modal-content.vue";
import reelPlay from "../../../assets/icon/reel_play.png";
import LoadMoreButton from "../../load-more-button.vue";
import {
  SESSION_DATA_LIMIT_DESKTOP,
  MEDIA_TYPE,
  CLICK_SETTING_REELS,
  CONFIGURATION_TYPE,
} from "../../../services/ultils";
import { UNIQUE_IDENTIFIER_TYPES, generateDataTestId } from "../../../constant/testingConstants";

export default {
  name: "grid-reel-arrangement",
  title: "Grid reel layout",
  components: { modalContainer, modalContent, LoadMoreButton },
  props: {
    options: { default: {} },
    dataTestId:{ default : null}
  },
  computed: {
    getSpacing() {
      return this.options.data.globalSettings.spacing.value;
    },
    groupImages() {
      const result = [];
      let currentGroup = [{ ...this.instagramData[0], actualIndex: 0 }];

      for (let i = 1; i < this.instagramData.length; i++) {
        if (i % this.limit == 0) {
          result.push(currentGroup);
          currentGroup = [{ ...this.instagramData[i], actualIndex: i }];
        } else {
          currentGroup.push({ ...this.instagramData[i], actualIndex: i });
        }
      }
      result.push(currentGroup);
      return result;
    },
    dataTestIdComputed(){
      return generateDataTestId(this.dataTestId,`${UNIQUE_IDENTIFIER_TYPES.DESKTOP}_${UNIQUE_IDENTIFIER_TYPES.GRID}`);
    }
  },
  data() {
    return {
      reelPlay: reelPlay,
      imageObject: null,
      imageIndex: 0,
      limit: 4,
      instagramData: [],
      isNextAvaiable: true,
      syncSession: null,
    };
  },
  methods: {
    handleOnClickAction(currentImageObj, currentImageIndex) {
      const onClickValue =
        this.options.data.onClickActions.onClickConfiguration.value;

      if (onClickValue === CLICK_SETTING_REELS.OPEN_IN_MODAL_WINDOW) {
        this.$refs.modal.openModal();
        this.imageObject = currentImageObj;
        this.imageIndex = currentImageIndex;
      } else if (onClickValue === CLICK_SETTING_REELS.SEE_ON_INSTAGRAM) {
        window.open(currentImageObj?.permalink, "_blank");
      }
    },
    previtem() {
      if (this.imageIndex > 0) {
        this.imageIndex -= 1;
      }
      if (this.instagramData[this.imageIndex]) {
        this.imageObject = this.instagramData[this.imageIndex];
      }
    },
    nextitem() {
      if (this.imageIndex >= this.instagramData.length) {
        this.imageIndex = this.instagramData.length;
      } else {
        this.imageIndex += 1;
      }
      if (this.instagramData[this.imageIndex]) {
        this.imageObject = this.instagramData[this.imageIndex];
      }
    },
    handleCloseModal() {
      this.$refs.modal.closeModal();
    },
    setInstagramData(value) {
      this.instagramData = value;
    },
    setIsNextAvaiable(value) {
      this.isNextAvaiable = value;
    },
    handleOnNext() {
      if (this.isNextAvaiable) {
        this.syncSession(
          this.nextUrl,
          this.instagramData,
          this.limit,
          this.getInstagramData,
          this.setInstagramData,
          this.setIsNextAvaiable,
          this.options.data.mode,
          this.options.data.company_id,
          this.options.data.application_id,
          CONFIGURATION_TYPE.REELS
        );
      }
    },

    async getInstagramData(nextUrl) {
      let postListResponse;
      try {
        if (nextUrl) {
          console.log("get next data from url in params");
        } else {
          postListResponse = await InstagramService.getPostList(
            SESSION_DATA_LIMIT_DESKTOP,
            this.options.data.mode,
            this.options.data.company_id,
            this.options.data.application_id
          );
        }
      } catch (error) {
        return error;
      }
      //
      let reelsOnly = postListResponse.data.data.filter(
        (x) => x.media_type === MEDIA_TYPE.VIDEO
      );

      const sessionKey = getSessionDataPrefix(
        this.options.data.mode,
        this.options.data.company_id,
        this.options.data.application_id,
        CONFIGURATION_TYPE.REELS
      );
      // get the Session data prefix to be used to store data
      window.sessionStorage.setItem(sessionKey, JSON.stringify(reelsOnly));

      window.sessionStorage.setItem(
        "nextUrl",
        JSON.stringify(this.nextUrl || "nextUrl")
      );
    },
  },
  async mounted() {
    // set data on session storage 100 saved on session
    await this.getInstagramData();
    this.syncSession = syncSessionWithState();
    this.syncSession(
      this.nextUrl,
      this.instagramData,
      this.limit,
      this.getInstagramData,
      this.setInstagramData,
      this.setIsNextAvaiable,
      this.options.data.mode,
      this.options.data.company_id,
      this.options.data.application_id,
      CONFIGURATION_TYPE.REELS
    );
  },
};
</script>

<style scoped>
.container {
  display: flex;
  flex-direction: column;
}

.grid-container {
  display: grid;
}
.dis-flex {
  display: flex;
}

.hrz-center {
  justify-content: center;
}

.video-item {
  width: 100%;
  height: 100%;
  object-fit: cover;
  cursor: pointer;
}

.pos-rel {
  position: relative;
  height: 500px;
}
.pos-rel .reel-play-overlay {
  display: none;
}

.pos-rel:hover .reel-play-overlay {
  display: block;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 40px;
  height: 40px;
}

@media all and (max-width: 767px) {
  .pos-rel {
    height: 300px;
  }
}
</style>
