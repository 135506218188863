<template>
  <div v-if="instagramData.length > 0" class="slider-container-instagram-feed" :data-testid="dataTestIdComputed">
    <!-- SLICK CAROUSAL -->
    <VueSlickCarousel
      v-bind="settings"
      class="carousel"
      ref="carousel"
      @beforeChange="beforeChange"
      @swipe="onSwipe"
    >
      <!-- EACH SLIDE -->
      <div
        v-for="(item, index) in groupImages"
        :key="index"
        class="slide"
      >
        <!-- EACH GRID -->
        <div
          class="grid-container"
          :style="{
            gap: getSpacing + 'px',
            gridTemplateColumns: `repeat(${getGridTemplateColumns}, 1fr)`,
            gridTemplateRows: `repeat(${getGridTemplateRows}, 1fr)`,
          }"
        >
          <div 
            v-for="(image, index) in item" 
            :key="index"
            @click="handleOnClickAction(image, image.actualIndex)"
          >
            <SingleMediaElement
              :mediaType="image.media_type"
              :imageObject="image"
            />
          </div>
        </div>
      </div>
    </VueSlickCarousel>
    <button
      :class="noPrev ? 'd-none' : 'slick-arrow slick-prev'"
      @click="showprev"
    >
      <img :src="prevBtnPng" class="next-btn-png" />
    </button>

    <button
      :class="noNext ? 'd-none' : 'slick-arrow slick-next'"
      @click="showNext"
    >
      <img :src="nextBtnPng" class="next-btn-png" />
    </button>
    <modalContainer ref="modal" id="scrolling_div" :isMobile="options.data.isMobile">
      <template>
        <modalContentResponsive
          :currentClickedImageIndex="imageIndex"
          :options="options"
          :parentLimit="this.limit"
          configType="POST_VIDEO"
        />
      </template>
    </modalContainer>
  </div>
</template>

<script>
import VueSlickCarousel from "vue-slick-carousel";
import "vue-slick-carousel/dist/vue-slick-carousel.css";
import "vue-slick-carousel/dist/vue-slick-carousel-theme.css";
import {
  getSessionDataPrefix,
  syncSessionWithState,
} from "../../../services/helper";
import modalContainer from "../../modal/modal-container.vue";
import modalContentResponsive from "../../modal/modal-content-responsive.vue";
import SingleMediaElement from "../../single-media-element.vue";
import InstagramService from "../../../services/instagram.service";
import {
  CLICK_SETTING,
  CONFIGURATION_TYPE,
  SESSION_DATA_LIMIT_MOBILE,
  MEDIA_TYPE,
  SWIPE_DIRECTION,
} from "../../../services/ultils";
import nextBtnPng from "../../../assets/icon/arrow_next.png";
import prevBtnPng from "../../../assets/icon/arrow_prev.png";
import { UNIQUE_IDENTIFIER_TYPES, generateDataTestId } from "../../../constant/testingConstants";

export default {
  name: "swiper-example-slides-per-column",
  title: "Multi row slides layout",
  components: {
    VueSlickCarousel,
    modalContainer,
    modalContentResponsive,
    SingleMediaElement,
  },
  props: {
    options: { default: {} },
    dataTestId:{ default : null}
  },
  computed: {
    getSpacing() {
      return this.options.data.globalSettings.spacing.value;
    },
    getGridTemplateColumns() {
      return 2;
    },
    getGridTemplateRows() {
      return 2;
    },
    groupImages() {
      const result = [];
      let currentGroup = [{ ...this.instagramData[0], actualIndex: 0 }];
      let moduloLimit = this.limit / 2;

      for (let i = 1; i < this.instagramData.length; i++) {
        if (i % moduloLimit == 0) {
          result.push(currentGroup);
          currentGroup = [{ ...this.instagramData[i], actualIndex: i }];
        } else {
          currentGroup.push({ ...this.instagramData[i], actualIndex: i });
        }
      }
      result.push(currentGroup);
      return result;
    },
    dataTestIdComputed(){
      return generateDataTestId(this.dataTestId,`${UNIQUE_IDENTIFIER_TYPES.MOBILE}_${UNIQUE_IDENTIFIER_TYPES.CAROUSAL}`);
    }
  },
  data() {
    return {
      prevBtnPng,
      nextBtnPng,
      settings: {
        lazyLoad: "ondemand",
        slidesToShow: 1,
        slidesToScroll: 1,
        infinite: false,
        swipeToSlide: true,
        arrows: false,
      },
      imageObject: null,
      imageIndex: 0,
      limit: 8,
      isNextAvaiable: true,
      instagramData: [],
      noPrev: true,
      noNext: false,
      syncSession: null,
    };
  },

  methods: {
    beforeChange(oldSlideIndex, newSlideIndex) {
      if (newSlideIndex === 0) {
        this.noPrev = true;
      } else {
        this.noPrev = false;
      }
      if (this.groupImages.length - 1 === newSlideIndex) {
        this.noNext = true;
      } else {
        this.noNext = false;
      }
    },
    onSwipe(direction) {
      if (direction === SWIPE_DIRECTION.LEFT) this.handleOnNext();
    },
    showprev() {
      if (!this.noPrev) {
        this.$refs.carousel.prev();
      }
    },
    showNext() {
      if (!this.noNext) {
        this.handleOnNext();
        this.$refs.carousel.next();
      }
    },
    handleOnClickAction(currentImageObj, currentImageIndex) {
      const onClickValue =
        this.options.data.onClickActions.onClickConfiguration.value;
      if (onClickValue === CLICK_SETTING.OPEN_IN_POPUP) {
        this.$refs.modal.openModal();
        this.imageObject = currentImageObj;
        this.imageIndex = currentImageIndex;
      } else if (onClickValue === CLICK_SETTING.OPEN_IN_INSTAGRAM) {
        window.open(currentImageObj?.permalink, "_blank");
      }
    },
    setInstagramData(value) {
      this.instagramData = value;
    },
    setIsNextAvaiable(value) {
      this.isNextAvaiable = value;
    },
    handleOnNext() {
      if (this.isNextAvaiable) {
        this.syncSession(
          this.nextUrl,
          this.instagramData,
          this.limit,
          this.getInstagramData,
          this.setInstagramData,
          this.setIsNextAvaiable,
          this.options.data.mode,
          this.options.data.company_id,
          this.options.data.application_id,
          CONFIGURATION_TYPE.POST_VIDEO
        );
      }
    },

    async getInstagramData(nextUrl) {
      let postListResponse;
      try {
        if (nextUrl) {
          console.log("get next data from url in params");
        } else {
          postListResponse = await InstagramService.getPostList(
            SESSION_DATA_LIMIT_MOBILE,
            this.options.data.mode,
            this.options.data.company_id,
            this.options.data.application_id
          );
        }
      } catch (error) {
        return error;
      }

      let sessionData = postListResponse.data.data;
      if (!this.options.data.globalSettings.includeVideos.value) {
        sessionData = sessionData.filter(
          (x) =>
            x.media_type === MEDIA_TYPE.IMAGE ||
            x.media_type === MEDIA_TYPE.CAROUSEL_ALBUM
        );
      }

      const sessionKey = getSessionDataPrefix(
        this.options.data.mode,
        this.options.data.company_id,
        this.options.data.application_id,
        CONFIGURATION_TYPE.POST_VIDEO
      );
      // get the Session data prefix to be used to store data
      window.sessionStorage.setItem(sessionKey, JSON.stringify(sessionData));

      window.sessionStorage.setItem(
        "nextUrl",
        JSON.stringify(this.nextUrl || "nextUrl")
      );
    },
  },
  async mounted() {
    // set data on session storage 100 saved on session
    await this.getInstagramData();
    this.syncSession = syncSessionWithState();
    this.syncSession(
      this.nextUrl,
      this.instagramData,
      this.limit,
      this.getInstagramData,
      this.setInstagramData,
      this.setIsNextAvaiable,
      this.options.data.mode,
      this.options.data.company_id,
      this.options.data.application_id,
      CONFIGURATION_TYPE.POST_VIDEO
    );
  },
};
</script>

<style scoped>
.slider-container-instagram-feed {
  position: relative;
}

.grid-container {
  display: grid;
}

.d-none {
  display: none;
}
.slick-next:before,
.slick-prev:before {
  font-family: slick;
  font-size: 20px;
  line-height: 1;
  opacity: 0.75;
  color: rgba(0, 0, 0, 0.656) !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  display: none;
}

.slick-next.slick-disabled:before,
.slick-prev.slick-disabled:before {
  color: #888 !important;
}

.next-btn-png {
  width: 100%;
  height: 100%;
}
.slick-next {
  right: -16px !important;
  top: 50% !important;
  height: 2rem;
  width: 2rem;
}

.slick-prev {
  left: -16px !important;
  top: 50% !important;
  height: 2rem;
  width: 2rem;
}
</style>
