<template>
  <div
    :class="
      themeType !== 'LIGHT' ? 'image-wrapper-black' : 'image-wrapper-white'
    "
    @click="handleClick"
    :style="customStyle"
  >
    <slot></slot>

    <img
      :class="
        overlayElements === 'SHOW_INSTAGRAM_ICON'
          ? 'instagram-icon'
          : 'dis-none'
      "
      :src="getOverlayIcon"
    />

    <div
      :class="
        overlayElements === 'SHOW_CAPTION' ? 'center-caption' : 'dis-none'
      "
    >
      <div class="caption-text">
        {{ trimCaption }}
      </div>
    </div>
  </div>
</template>

<script>
import InstaIconDark from "../assets/icon/instagram_icon.svg";
import InstaIconLight from "../assets/icon/instagram_icon_black.svg";

export default {
  name: "image-wrapper",
  title: "Image wrapper",
  props: {
    caption: { type: String, default: "" },
    hoverEffects: { default: {} },
    onClickPopup: Function,
    customStyle: { default: {} },
  },

  computed: {
    themeType() {
      return this.hoverEffects.overlayTheme.value;
    },
    overlayElements() {
      return this.hoverEffects.overlayElements.value;
    },
    getOverlayIcon() {
      return this.hoverEffects.overlayTheme.value === "LIGHT"
        ? InstaIconLight
        : InstaIconDark;
    },
    trimCaption() {
      if (this.caption && this.caption.length > 100)
        return this.caption.slice(0, 100) + "...";
      return this.caption;
    },
  },
  methods: {
    handleClick() {
      this.$emit("onClickPopup");
    },
  },
  data() {
    return {
      instagramIcon: null,
      showModal: false,
    };
  },
};
</script>

<style scoped>
.image-wrapper-white {
  cursor: pointer;
  display: grid;
  position: relative;
}

.image-wrapper-white:after {
  content: attr(data-content);
  color: #fff;
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background: rgba(255, 255, 255, 0.6);
  opacity: 0;
  transition: all 0.5s;
  -webkit-transition: all 0.5s;
}

.image-wrapper-white:hover:after {
  opacity: 1;
}

.image-wrapper-white:hover .instagram-icon {
  opacity: 1;
}

.image-wrapper-black {
  cursor: pointer;
  display: grid;
  position: relative;
}

.image-wrapper-black:after {
  content: attr(data-content);
  color: #fff;
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.6);
  opacity: 0;
  transition: all 0.5s;
  -webkit-transition: all 0.5s;
}

.image-wrapper-black:hover:after {
  opacity: 1;
}

.instagram-icon {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  opacity: 0;
  height: 25px;
  width: 25px;
  z-index: 100;
}

.image-wrapper-black:hover .instagram-icon {
  opacity: 1;
}

.image-wrapper-black:hover .center-caption {
  text-align: left;
  opacity: 1;
  color: #fff;
  z-index: 2;
  top: 50%;
}

.image-wrapper-white:hover .center-caption {
  text-align: left;
  opacity: 1;
  color: #000;
  z-index: 2;
  top: 50%;
}

.dis-none {
  display: none;
}

.center-caption {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 80%;
  opacity: 0;
  display: flex;
  justify-content: center;
}

.caption-text {
  text-align: left;
  /* font styling to be fetched from storefront */
  /* fallback color as present in preview */
  font-family: unset;

  /* Using Storefront font as per design */
  font-weight: 400;
  font-size: 16px;
}

.w-play {
  width: 30px;
  height: 30px;
}

@media all and (max-width: 767px) {
  .caption-text {
    font-size: 12px;
  }
}
</style>
