<template>
  <div v-if="show" :data-testid="dataTestIdComputed">
    <h2
      class="dis-flex heading font-header"
      :style="{ justifyContent: allignmentType }"
    >
      {{ value }}
    </h2>
  </div>
</template>

<script>
import { generateDataTestId } from '../constant/testingConstants';
export default {
  name: "main-heading",
  title: "Main Heading",
  props: {
    value: {
      default: "",
    },
    allignmentType: {
      default: "center",
    },
    show: {
      default: false,
    },
    dataTestId:{
      default:null
    }
  },
  computed:{
    dataTestIdComputed(){
      return generateDataTestId(this.dataTestId,`Heading_${this.allignmentType}`);
    }
  }
};
</script>

<style scoped>
.dis-flex {
  display: flex;
}
.heading {
  margin: 16px 0 16px 0;
  /* font styling to be fetched from storefront */
  /* fallback color as present in preview */
  font-family: unset;
  color: var(--textHeading, #5c5c5c);
  letter-spacing: -0.03em;

  /* Using Storefront font as per design */
  font-weight: 700;
  font-size: 32px;
}

/* Media queries for preview, and storefront mobile view */
@media all and (max-width: 767px) {
  .heading {
    font-size: 24px;
    margin: 16px 0 12px 0;
  }
}
</style>
