<template>
  <!-- SLICK CAROUSAL -->
  <div v-if="albumData && albumData.length > 0" class="album-slider-container">
    <VueSlickCarousel
      v-bind="settings"
      :style="{ height: '100%' }"
      class="carousel"
      ref="carousel"
    >
      <!-- EACH SLIDE -->
      <div v-for="(item, index) in albumData" :key="index" class="slide">
        <div
          class="slide-container-instagram-feed"
          v-if="item.media_type === 'IMAGE'"
        >
          <ImageComponent :mediaUrl="item.media_url" />
        </div>
        <div
          class="slide-container-instagram-feed"
          v-if="item.media_type === 'VIDEO'"
        >
          <VideoComponent :mediaUrl="item.media_url" />
        </div>
      </div>
    </VueSlickCarousel>
  </div>
</template>

<script>
import VueSlickCarousel from "vue-slick-carousel";
import "vue-slick-carousel/dist/vue-slick-carousel.css";
import "vue-slick-carousel/dist/vue-slick-carousel-theme.css";
import ImageComponent from "./image-component.vue";
import VideoComponent from "./video-component.vue";
import MainServices from '../../services/instagram.service';

export default {
  name: "album-slider-component",
  props: {
    albumItem: { default: {} },
    options:{default: {}}
  },
  components: {
    VueSlickCarousel,
    ImageComponent,
    VideoComponent,
  },
  async mounted() {
    await this.getAllAlbumMedia(this.albumItem);
  },
  data() {
    return {
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
        infinite: false,
        arrows: false,
        dots: true,
        dotsClass: "slick-dots custom-dots",
        swipeToSlide: true,
        swipe: true,
        touchMove: true,
      },
      albumData:[]
    };
  },
  methods:{
    async getAllAlbumMedia(imageObject) {
      let promiseArray = [];
      if (imageObject?.children) {
        const mediaData = imageObject.children.data;
        for (let i in mediaData) {
          // CALL THE SERVICE HERE TO GET MEDIA ITEM DATA and push it to albumData state
          const promise = MainServices.getImageListChildren(
            imageObject.children.data[i].id,
            this.options.data.mode,
            this.options.data.company_id,
            this.options.data.application_id
          );
          promiseArray.push(promise);
        }
        const res = await Promise.all(promiseArray);
        const data = res.map((res) => res.data);
        this.albumData = data;
      }
    },
  }
};
</script>

<style scoped>
.album-slider-container {
  height: 100%;
  position: relative;
}

.slide-container-instagram-feed {
  height: 100%;
}
.slide {
  height: 100%;
}

::v-deep .custom-dots {
  position: absolute;
  bottom: 8px !important;
}
</style>
