<template>
  <div class="container" v-if="imageObject && mediaType">
    <img
      :src="mediaUrl"
      alt="media element"
      class="aspect-ratio-square height-100-per of-cover"
    />
    <div v-if="mediaIcon" class="media-icon-container">
      <img :src="mediaIcon" class="media-icon" />
    </div>
  </div>
</template>

<script>
import { MEDIA_TYPE } from "../services/ultils";
import AlbumMediaIcon from "../assets/icon/album_media_icon.svg";
import VideoMediaIcon from "../assets/icon/video_media_icon.svg";

export default {
  name: "single-media-element",
  title: "Single Media Element",
  props: {
    mediaType: { default: "" },
    imageObject: { default: null },
  },
  computed: {
    mediaUrl() {
      if (
        this.mediaType === MEDIA_TYPE.IMAGE ||
        this.mediaType === MEDIA_TYPE.CAROUSEL_ALBUM
      )
        return this.imageObject.media_url;

      return this.imageObject.thumbnail_url;
    },
    mediaIcon() {
      if (this.mediaType === MEDIA_TYPE.CAROUSEL_ALBUM) return AlbumMediaIcon;
      else if (this.mediaType === MEDIA_TYPE.VIDEO) return VideoMediaIcon;
      return false;
    },
  },
};
</script>

<style scoped>
.container {
  position: relative;
  cursor: pointer;
}
.media-icon-container {
  position: absolute;
  top: 9px;
  right: 12px;
  width: 1.2rem;
  height: 1.2rem;
}

.media-icon {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.height-100-per {
  height: 100%;
}

.aspect-ratio-square {
  width: 100%;
  aspect-ratio: 1/1;
}

.of-cover {
  object-fit: cover;
  display: block;
}
</style>
