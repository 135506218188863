<template>
  <div :data-testid="dataTestIdComputed">
    <MainHeading
      :allignmentType="allignmentType"
      :value="getHeading"
      :show="showHeading"
      :dataTestId="dataTestIdComputed"
    />

    <SubHeading
      :allignmentType="allignmentType"
      :value="getSubHeading"
      :show="showSubHeading"
      :dataTestId="dataTestIdComputed"
    />
    <component v-bind:is="getCorrectResponsiveLayout" :options="options"  :dataTestId="dataTestIdComputed"/>
  </div>
</template>

<script>
import GridReelArrangement from "./grid/grid-reel-arrangement.vue";
import GridReelArrangementResponsive from "./grid/grid-reel-arrangement-responsive.vue";
import ReelArrangement from "./carousel/reel-arrangement.vue";
import ReelArrangementResponsive from "./carousel/reel-arrangement-responsive.vue";
import SubHeading from "../sub-heading.vue";
import MainHeading from "../main-heading.vue";
import { UNIQUE_IDENTIFIER_TYPES, generateDataTestId } from '../../constant/testingConstants';

export default {
  name: "reel-arrangement-main",
  title: "Reel arrangement main",

  props: {
    options: { default: {} },
    dataTestId:{ default : null}
  },
  components: {
    GridReelArrangement,
    ReelArrangement,
    GridReelArrangementResponsive,
    ReelArrangementResponsive,
    SubHeading,
    MainHeading,
  },
  data() {
    return {
      component: {
        GRID: "GridReelArrangement",
        CAROUSEL: "ReelArrangement",
      },
      componentResponsive: {
        GRID: "GridReelArrangementResponsive",
        CAROUSEL: "ReelArrangementResponsive",
      },
    };
  },
  computed: {
    allignmentType() {
      return this.options.data.globalSettings.title.allignment.value;
    },
    showHeading() {
      return this.options.data.globalSettings.title.heading.show;
    },
    showSubHeading() {
      return (
        this.showHeading &&
        this.options.data.globalSettings.title.subHeading.show
      );
    },
    getHeading() {
      return this.options.data.globalSettings.title.heading.content;
    },
    getSubHeading() {
      return this.options.data.globalSettings.title.subHeading.content;
    },
    getActiveLayout() {
      return this.options.data.globalSettings.layoutTemplate.value;
    },
    getCorrectResponsiveLayout() {
      if (this.options.data.isMobile)
        return this.componentResponsive[this.getActiveLayout];
      return this.component[this.getActiveLayout];
    },
    dataTestIdComputed(){
      return generateDataTestId(this.dataTestId,`Reels_${UNIQUE_IDENTIFIER_TYPES.ARRANGEMENT}`);
    }
  },
};
</script>

<style scoped></style>
