<template>
  <div v-if="config" class="default-margin font-body" :data-testid="dataTestIdComputed">
    <component v-bind:is="componentMain" :options="getOptions" :dataTestId="dataTestIdComputed"/>
  </div>
</template>

<script>
import RandomArrangementMain from "./components/random/random-arrangement-main.vue";
import SquareArrangementMain from "./components/square/square-arrangement-main.vue";
import GalleryArrangementMain from "./components/gallery/gallery-arrangement-main.vue";
import SocialArrangementMain from "./components/social/social-arrangement-main.vue";
import { MODE } from "./services/ultils";
import MainServices from "./services/instagram.service";
import { generateDataTestId } from "./constant/testingConstants";

export default {
  components: {
    RandomArrangementMain,
    SquareArrangementMain,
    GalleryArrangementMain,
    SocialArrangementMain,
  },
  props:{
    dataTestId:{
      default: null
    }
  },
  computed: {
    componentMain() {
      return this.component[this.config.data.globalSettings.arrangements.value];
    },
    getOptions() {
      return {
        data: {
          ...this.config.data,
          isMobile: this.isMobile,
          mode: this.mode,
          company_id: null,
          application_id: null,
        },
      };
    },
    dataTestIdComputed(){
      return generateDataTestId(this.dataTestId,"Storefront");
    }
  },

  data() {
    return {
      config: false,
      isMobile: null,
      mode: MODE[0],
      component: {
        RANDOM_ARRANGEMENT: "RandomArrangementMain",
        SQUARE_ARRANGEMENT: "SquareArrangementMain",
        GALLERY_ARRANGEMENT: "GalleryArrangementMain",
        SOCIAL_ARRANGEMENT: "SocialArrangementMain",
      },
    };
  },
  methods: {
    getIsMobile() {
      // TO BE USED WHEN MOBILE COMPONENT WILL BE READY
      if (
        navigator.userAgent.match(/Android/i) ||
        navigator.userAgent.match(/webOS/i) ||
        navigator.userAgent.match(/iPhone/i) ||
        navigator.userAgent.match(/iPad/i) ||
        navigator.userAgent.match(/iPod/i) ||
        navigator.userAgent.match(/BlackBerry/i) ||
        navigator.userAgent.match(/Windows Phone/i)
      ) {
        return true;
      }
      return false;
    },
    async getConfig() {
      const res = await MainServices.getConfiguration();
      return res.data;
    },
  },
  async mounted() {
    this.isMobile = this.getIsMobile();
    // GET CONFIG FOR PARTICULAR COMPANY_ID AND APPLICATION_ID
    const data = await this.getConfig();
    this.config = {
      data: data.post_configs,
    }; // USING post_configs data for POST VIDEO BINDINGS
  },
};
</script>

<style>
.default-margin {
  margin: 24px;
}

.slick-list {
  height: 100%;
}

.slick-track {
  height: 100%;
}

.slick-slide {
  height: 100%;
}

.slick-slide > div {
  height: 100%;
}

.slick-dots {
  bottom: 12px;
}

.slick-dots li button::before {
  color: grey;
}

.slick-dots li.slick-active button::before {
  color: white;
}

.slick-next {
  right: 9px;
  z-index: 10;
}

.slick-prev {
  left: 2px;
  z-index: 10;
}
</style>
