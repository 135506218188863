<template>
  <div v-if="show" :data-testid="dataTestIdComputed">
    <h3 class="dis-flex subheading" :style="{ justifyContent: allignmentType }">
      {{ value }}
    </h3>
  </div>
</template>

<script>
import { generateDataTestId } from '../constant/testingConstants';
export default {
  name: "sub-heading",
  title: "Sub Heading",
  props: {
    value: {
      default: "",
    },
    allignmentType: {
      default: "center",
    },
    show: {
      default: false,
    },
    dataTestId:{
      default:null
    }
  },
  computed:{
    dataTestIdComputed(){
      return generateDataTestId(this.dataTestId,`Subheading_${this.allignmentType}`);
    }
  }
};
</script>

<style scoped>
.dis-flex {
  display: flex;
}
.subheading {
  margin: 0 0 32px 0;

  /* font styling to be fetched from storefront */
  /* fallback color as present in preview */
  font-family: unset;
  color: var(--textBody, #5c5c5c);

  /* Using Storefront font as per design */
  font-weight: 600;
  font-size: 20px;
}

/* Media queries for preview and storefront mobile view*/
@media all and (max-width: 767px) {
  .subheading {
    font-size: 14px;
    margin: 0 0 16px 0;
  }
}
</style>
