export const generateDataTestId = (parentDataTestId,componentSuffix) =>{
  if(parentDataTestId)
    return `${parentDataTestId}_${componentSuffix}`;
  return componentSuffix;
}

export const getDeviceType = (isMobile) =>{
  if(isMobile)
    return "Mobile";
  return "Desktop";
}

export const UNIQUE_IDENTIFIER_TYPES = {
  ARRANGEMENT:"Arrangement",
  GRID:"Grid",
  CAROUSAL:"Carousal",
  DESKTOP:"Desktop",
  MOBILE:"Mobile"
}
