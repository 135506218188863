import { url } from "./urlset";

export const datedifference = (actualDate, value) => {
  // Calculate time between two dates:
  const date1 = actualDate; // the date you already commented/ posted
  const date2 = new Date(); // today
  let r = {}; // object for clarity
  let message;
  const diffInSeconds = Math.abs(date2 - date1) / 1000;
  const days = Math.floor(diffInSeconds / 60 / 60 / 24);
  const hours = Math.floor((diffInSeconds / 60 / 60) % 24);
  const minutes = Math.floor((diffInSeconds / 60) % 60);
  const seconds = Math.floor(diffInSeconds % 60);
  const milliseconds = Math.round(
    (diffInSeconds - Math.floor(diffInSeconds)) * 1000
  );
  const weeks = Math.floor(days / 7);
  const months = Math.floor(days / 30);
  const years = Math.floor(months / 12);
  r = {
    years: years,
    months: months,
    weeks: weeks,
    days: days,
    hours: hours,
    minutes: minutes,
    seconds: seconds,
    milliseconds: milliseconds,
  };

  if (years === 0 && months === 0) {
    // show in weeks if no years / months
    if (weeks > 0) {
      if (weeks === 1) {
        message = weeks + " week";
      } else {
        message = weeks + " weeks";
      }
    } else if (days > 0) {
      if (days === 1) {
        message = days + " day";
      } else {
        message = days + " days ";
      }
    } else if (hours > 0) {
      // show in hours if no years / months / days
      if (hours === 1) {
        message = hours + " hour";
      } else {
        message = hours + " hours";
      }
    } else {
      // show in minutes if no years / months / days / hours
      if (minutes === 1) {
        message = minutes + " minute";
      } else {
        message = minutes + " minutes ";
      }
    }
  } else if (years === 0 && months > 0) {
    // show in months if no years
    if (months === 1) {
      message = months + " month";
    } else {
      message = months + " months ";
    }
  } else if (years > 0) {
    // show in years if years exist
    if (years === 1) {
      message = years + " year";
    } else {
      message = years + " years ";
    }
  }

  // To display either an object or a message in the view
  if (value === true) {
    return r;
  }
  return message;
};
export const extended_split = (str, separator, max) => {
  var out = [],
    index = 0,
    next;
  while (!max || out.length < max - 1) {
    next = str.indexOf(separator, index);
    if (next === -1) {
      break;
    }
    out.push(str.substring(index, next));
    index = next + separator.length;
  }
  out.push(str.substring(index));
  return out;
};

export const MODE = ["APPLICATION", "PREVIEW"];
export const CONFIGURATION_TYPE = {
  POST_VIDEO: "POST_VIDEO",
  REELS: "REELS",
};

const MEDIA_LIST_ENDPOINT = {
  STOREFRONT: `${url}/ext/instagram-feed/api/v2/media/list`,
  PREVIEW: `${url}/api/v2/preview/media/list`,
};

const MEDIA_LIST_ITEM_ENDPOINT = {
  STOREFRONT: `${url}/ext/instagram-feed/api/v2/media/listitem`,
  PREVIEW: `${url}/api/v2/preview/media/listitem`,
};

export const getMediaListEndpoint = (mode, application_id) => {
  if (mode === MODE[0]) return MEDIA_LIST_ENDPOINT.STOREFRONT;
  return `${MEDIA_LIST_ENDPOINT.PREVIEW}/${application_id}`;
};

export const getMediaListItemEndpoint = (mode, itemId, application_id) => {
  if (mode === MODE[0])
    return `${MEDIA_LIST_ITEM_ENDPOINT.STOREFRONT}/${itemId}`;
  return `${MEDIA_LIST_ITEM_ENDPOINT.PREVIEW}/${itemId}/${application_id}`;
};

export const SESSION_DATA_LIMIT_DESKTOP = 100;
export const SESSION_DATA_LIMIT_MOBILE = 50;
export const CLICK_SETTING = {
  OPEN_IN_POPUP: "OPEN_IN_POPUP",
  OPEN_IN_INSTAGRAM: "OPEN_ON_INSTAGRAM",
};

export const CLICK_SETTING_REELS = {
  OPEN_IN_MODAL_WINDOW: "OPEN_IN_MODAL_WINDOW",
  SEE_ON_INSTAGRAM: "SEE_ON_INSTAGRAM",
};

export const MEDIA_TYPE = {
  IMAGE: "IMAGE",
  VIDEO: "VIDEO",
  CAROUSEL_ALBUM: "CAROUSEL_ALBUM",
};

export const SWIPE_DIRECTION = {
  LEFT: "left",
  RIGHT: "right",
};

export const RESPONSE_HEADERS = {
  COMPANY_ID: "x-if-company-id",
  APPLICATION_ID: "x-if-application-id",
};
