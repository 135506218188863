import InstagramPostVideo from "./InstagramPostVideo.vue";
import InstagramReels from "./InstagramReels.vue";

// OLD BINDINGS FOR STOREFRONT
window.FPI.extension.register("#instagram-feed", {
  mounted(element) {
    window.FPI.extension.mountApp({
      element,
      component: InstagramPostVideo,
    });
  },
});

// NEW REELS BINDINGS
window.FPI.extension.register("#instagram-feed-reels", {
  mounted(element) {
    console.log("REGISTERING_REELS_BINDING");
    window.FPI.extension.mountApp({
      element,
      component: InstagramReels,
    });
  },
});
