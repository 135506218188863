<template>
  <div v-if="instagramData.length > 0" class="slider-container-instagram-feed" :data-testid="dataTestIdComputed">
    <VueSlickCarousel
      v-bind="settings"
      class="carousel"
      ref="carousel"
      @beforeChange="beforeChange"
      @swipe="onSwipe"
    >
      <div v-for="(item, index) in groupImages" :key="index">
        <div class="grid-container" :style="{ gap: getSpacing + 'px' }">
          <div
            class="grid-of-one"
            @click="handleOnClickAction(item.image, item.image.actualIndex)"
          >
            <SingleMediaElement
              :mediaType="item.image.media_type"
              :imageObject="item.image"
            />
          </div>

          <div class="grid-of-two" :style="{ gap: getSpacing + 'px' }">
            <div
              v-for="(image, i) in item.images"
              :key="i"
              @click="handleOnClickAction(image.image, image.image.actualIndex)"
            >
              <SingleMediaElement
                :mediaType="image.image.media_type"
                :imageObject="image.image"
              />
            </div>
          </div>
        </div>
      </div>
    </VueSlickCarousel>

    <button
      :class="noPrev ? 'd-none' : 'slick-arrow slick-prev'"
      @click="showprev"
    >
      <img :src="prevBtnPng" class="next-btn-png" />
    </button>

    <button
      :class="noNext ? 'd-none' : 'slick-arrow slick-next'"
      @click="showNext"
    >
      <img :src="nextBtnPng" class="next-btn-png" />
    </button>

    <modalContainer ref="modal" id="scrolling_div" :isMobile="options.data.isMobile">
      <template>
        <modalContentResponsive
            :currentClickedImageIndex="imageIndex"
            :options="options"
            :parentLimit="this.limit"
            configType="POST_VIDEO"
        />
      </template>
    </modalContainer>
  </div>
</template>

<script>
import VueSlickCarousel from "vue-slick-carousel";
import "vue-slick-carousel/dist/vue-slick-carousel.css";
import "vue-slick-carousel/dist/vue-slick-carousel-theme.css";
import InstagramService from "../../../services/instagram.service";
import {
  getSessionDataPrefix,
  syncSessionWithState,
} from "../../../services/helper";
import modalContainer from "../../modal/modal-container.vue";
import modalContentResponsive from "../../modal/modal-content-responsive.vue";
import SingleMediaElement from "../../single-media-element.vue";
import {
  CLICK_SETTING,
  CONFIGURATION_TYPE,
  MEDIA_TYPE,
  SESSION_DATA_LIMIT_MOBILE,
  SWIPE_DIRECTION,
} from "../../../services/ultils";
import nextBtnPng from "../../../assets/icon/arrow_next.png";
import prevBtnPng from "../../../assets/icon/arrow_prev.png";
import { UNIQUE_IDENTIFIER_TYPES, generateDataTestId } from "../../../constant/testingConstants";

export default {
  name: "gallery-arrangement",
  title: "Gallery Arrangement",
  props: {
    options: { default: {} },
    dataTestId:{ default : null}
  },
  components: {
    VueSlickCarousel,
    modalContainer,
    modalContentResponsive,
    SingleMediaElement,
  },

  computed: {
    getSpacing() {
      return this.options.data.globalSettings.spacing.value;
    },
    groupImages() {
      const result = [];
      let imageGroupInstaData = this.instagramData;

      let currentGroup = {
        image: { ...imageGroupInstaData[0], actualIndex: 0 },
        images: [],
      };
      for (let i = 1; i < imageGroupInstaData.length; i++) {
        if (i % 3 == 0) {
          result.push(currentGroup);
          currentGroup = {
            image: { ...imageGroupInstaData[i], actualIndex: i },
            images: [],
          };
        } else {
          currentGroup.images.push({
            image: { ...imageGroupInstaData[i], actualIndex: i },
          });
        }
      }

      result.push(currentGroup);
      return result;
    },
    dataTestIdComputed(){
      return generateDataTestId(this.dataTestId,`${UNIQUE_IDENTIFIER_TYPES.MOBILE}_${UNIQUE_IDENTIFIER_TYPES.CAROUSAL}`);
    }
  },

  watch: {
    options(newOptions) {
      this.reload(newOptions);
    },
  },

  data() {
    return {
      prevBtnPng,
      nextBtnPng,
      settings: {
        lazyLoad: "ondemand",
        slidesToShow: 1,
        slidesToScroll: 1,
        infinite: false,
        swipeToSlide: true,
        arrows: false,
      },
      limit: 6,
      instagramData: [],
      isNextAvaiable: true,
      show: true,
      imageObject: null,
      imageIndex: 0,
      noPrev: true,
      noNext: false,
      syncSession: null,
    };
  },

  methods: {
    beforeChange(oldSlideIndex, newSlideIndex) {
      if (newSlideIndex === 0) {
        this.noPrev = true;
      } else {
        this.noPrev = false;
      }
      if (this.groupImages.length - 1 === newSlideIndex) {
        this.noNext = true;
      } else {
        this.noNext = false;
      }
    },
    onSwipe(direction) {
      if (direction === SWIPE_DIRECTION.LEFT) this.handleOnNext();
    },
    setInstagramData(value) {
      this.instagramData = value;
    },
    setIsNextAvaiable(value) {
      this.isNextAvaiable = value;
    },
    showprev() {
      if (!this.noPrev) {
        this.$refs.carousel.prev();
      }
    },
    showNext() {
      if (!this.noNext) {
        this.handleOnNext();
        this.$refs.carousel.next();
      }
    },
    handleOnClickAction(currentImageObj, currentImageIndex) {
      const onClickValue =
        this.options.data.onClickActions.onClickConfiguration.value;
      if (onClickValue === CLICK_SETTING.OPEN_IN_POPUP) {
        this.$refs.modal.openModal();
        this.imageObject = currentImageObj;
        this.imageIndex = currentImageIndex;
      } else if (onClickValue === CLICK_SETTING.OPEN_IN_INSTAGRAM) {
        window.open(currentImageObj?.permalink, "_blank");
      }
    },
    handleOnNext() {
      if (this.isNextAvaiable) {
        this.syncSession(
          this.nextUrl,
          this.instagramData,
          this.limit,
          this.getInstagramData,
          this.setInstagramData,
          this.setIsNextAvaiable,
          this.options.data.mode,
          this.options.data.company_id,
          this.options.data.application_id,
          CONFIGURATION_TYPE.POST_VIDEO
        );
      }
    },
    reload(newOptions) {
      this.show = false;
      this.swiperOption = {
        ...this.swiperOption,
        spaceBetween: newOptions.data.globalSettings.spacing.value,
      };
      setTimeout(() => {
        this.show = true;
      }, 1);
    },

    async getInstagramData(nextUrl) {
      let postListResponse;
      try {
        if (nextUrl) {
          console.log("get next data from url in params");
        } else {
          postListResponse = await InstagramService.getPostList(
            SESSION_DATA_LIMIT_MOBILE,
            this.options.data.mode,
            this.options.data.company_id,
            this.options.data.application_id
          );
        }
      } catch (error) {
        return error;
      }
      let sessionData = postListResponse.data.data;
      if (!this.options.data.globalSettings.includeVideos.value) {
        sessionData = sessionData.filter(
          (x) =>
            x.media_type === MEDIA_TYPE.IMAGE ||
            x.media_type === MEDIA_TYPE.CAROUSEL_ALBUM
        );
      }
      const sessionKey = getSessionDataPrefix(
        this.options.data.mode,
        this.options.data.company_id,
        this.options.data.application_id,
        CONFIGURATION_TYPE.POST_VIDEO
      );
      // get the Session data prefix to be used to store data
      window.sessionStorage.setItem(sessionKey, JSON.stringify(sessionData));
      window.sessionStorage.setItem(
        "nextUrl",
        JSON.stringify(this.nextUrl ? this.nextUrl : false)
      );
    },
  },

  async mounted() {
    this.reload(this.options);
    // set data on session storage 100 saved on session
    await this.getInstagramData(); // session data
    this.syncSession = syncSessionWithState();
    this.syncSession(
      this.nextUrl,
      this.instagramData,
      this.limit,
      this.getInstagramData,
      this.setInstagramData,
      this.setIsNextAvaiable,
      this.options.data.mode,
      this.options.data.company_id,
      this.options.data.application_id,
      CONFIGURATION_TYPE.POST_VIDEO
    );
  },
};
</script>

<style scoped>
.slider-container-instagram-feed {
  position: relative;
}

.grid-container {
  display: grid;
  grid-template-columns: 2fr 1fr;
}

.grid-of-one{
  display: grid;
}
.grid-of-two {
  display: grid;
  grid-template-columns: auto;
  grid-template-rows: 1fr 1fr;
}

.d-none {
  display: none;
}
.slick-next:before,
.slick-prev:before {
  font-family: slick;
  font-size: 20px;
  line-height: 1;
  opacity: 0.75;
  color: rgba(0, 0, 0, 0.656) !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  display: none;
}

.slick-next.slick-disabled:before,
.slick-prev.slick-disabled:before {
  color: #888 !important;
}
.next-btn-png {
  width: 100%;
  height: 100%;
}
.slick-next {
  right: -16px !important;
  top: 50% !important;
  height: 2rem;
  width: 2rem;
}

.slick-prev {
  left: -16px !important;
  top: 50% !important;
  height: 2rem;
  width: 2rem;
}
</style>
