<template>
  <div class="image-container">
    <img :src="mediaUrl" alt="instagram media image" />
    <div class="tag-container">
      <img src="../../assets/icon/image_tag.svg" alt="shoping tag" />
    </div>
  </div>
</template>

<script>
export default {
  name: "image-component",
  props: {
    mediaUrl: { default: "" },
  },
};
</script>

<style lang="scss" scoped>
.image-container {
  width: 100%;
  height: 100%;
  position: relative;
  display: flex;
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  .tag-container {
    position: absolute;
    left: 8px;
    bottom: 8px;
  }
}
</style>
