<template>
  <div :data-testid="dataTestIdComputed">
    <div
      class="parent-grid-container"
      :style="{ marginBottom: getSpacing + 'px' }"
      v-for="(item, index) in groupImages"
      :key="index"
    >
      <div class="grid-container" :style="{ gap: getSpacing + 'px' }">
        <div
          @click="handleOnClickAction(item.image, item.image.actualIndex)"
          class="grid-of-one"
        >
          <SingleMediaElement
            :mediaType="item.image.media_type"
            :imageObject="item.image"
          />
        </div>

        <div class="grid-of-two" :style="{ gap: getSpacing + 'px' }">
          <div
            v-for="image in item.images"
            :key="image.image.actualIndex"
            @click="handleOnClickAction(image.image, image.image.actualIndex)"
          >
            <SingleMediaElement
              :mediaType="image.image.media_type"
              :imageObject="image.image"
            />
          </div>
        </div>
      </div>
    </div>
    <div class="dis-flex hrz-center">
      <LoadMoreButton
        :isNextAvaiable="isNextAvaiable"
        @handleClick="handleOnNext"
      />
    </div>
    <modalContainer ref="modal" id="scrolling_div" :isMobile="options.data.isMobile">
      <template>
          <modalContentResponsive
            :currentClickedImageIndex="imageIndex"
            :options="options"
            :parentLimit="this.limit"
            configType="POST_VIDEO"
          />
      </template>
    </modalContainer>
  </div>
</template>

<script>
import modalContainer from "../../modal/modal-container.vue";
import modalContentResponsive from "../../modal/modal-content-responsive.vue";
import LoadMoreButton from "../../load-more-button.vue";
import SingleMediaElement from "../../single-media-element.vue";
import {
  getSessionDataPrefix,
  syncSessionWithState,
} from "../../../services/helper";
import {
  CLICK_SETTING,
  CONFIGURATION_TYPE,
  MEDIA_TYPE,
  SESSION_DATA_LIMIT_MOBILE,
} from "../../../services/ultils";
import InstagramService from "../../../services/instagram.service";
import { generateDataTestId, UNIQUE_IDENTIFIER_TYPES } from '../../../constant/testingConstants';

export default {
  name: "grid-random-arrangement",
  title: "Grid random layout",
  components: {
    modalContainer,
    modalContentResponsive,
    LoadMoreButton,
    SingleMediaElement,
  },
  props: {
    options: { default: {} },
    dataTestId:{ default : null }
  },
  computed: {
    getSpacing() {
      return this.options.data.globalSettings.spacing.value;
    },
    groupImages() {
      const result = [];
      let imageGroupInstaData = this.instagramData;

      let currentGroup = {
        image: { ...imageGroupInstaData[0], actualIndex: 0 },
        images: [],
      };
      for (let i = 1; i < imageGroupInstaData.length; i++) {
        if (i % 3 == 0) {
          result.push(currentGroup);
          currentGroup = {
            image: { ...imageGroupInstaData[i], actualIndex: i },
            images: [],
          };
        } else {
          currentGroup.images.push({
            image: { ...imageGroupInstaData[i], actualIndex: i },
          });
        }
      }

      result.push(currentGroup);
      return result;
    },
    dataTestIdComputed(){
      return generateDataTestId(this.dataTestId,`${UNIQUE_IDENTIFIER_TYPES.MOBILE}_${UNIQUE_IDENTIFIER_TYPES.GRID}`);
    }
  },
  data() {
    return {
      imageObject: null,
      imageIndex: 0,
      limit: 3,
      isNextAvaiable: true,
      instagramData: [],
      syncSession: null,
    };
  },
  methods: {
    handleOnClickAction(currentImageObj, currentImageIndex) {
      const onClickValue =
        this.options.data.onClickActions.onClickConfiguration.value;
      if (onClickValue === CLICK_SETTING.OPEN_IN_POPUP) {
        this.$refs.modal.openModal();
        this.imageObject = currentImageObj;
        this.imageIndex = currentImageIndex;
      } else if (onClickValue === CLICK_SETTING.OPEN_IN_INSTAGRAM) {
        window.open(currentImageObj?.permalink, "_blank");
      }
    },
    setInstagramData(value) {
      this.instagramData = value;
    },
    setIsNextAvaiable(value) {
      this.isNextAvaiable = value;
    },
    handleOnNext() {
      if (this.isNextAvaiable) {
        this.syncSession(
          this.nextUrl,
          this.instagramData,
          this.limit,
          this.getInstagramData,
          this.setInstagramData,
          this.setIsNextAvaiable,
          this.options.data.mode,
          this.options.data.company_id,
          this.options.data.application_id,
          CONFIGURATION_TYPE.POST_VIDEO
        );
      }
    },
    
    async getInstagramData(nextUrl) {
      let postListResponse;
      try {
        if (nextUrl) {
          console.log("get next data from url in params");
        } else {
          postListResponse = await InstagramService.getPostList(
            SESSION_DATA_LIMIT_MOBILE,
            this.options.data.mode,
            this.options.data.company_id,
            this.options.data.application_id
          );
        }
      } catch (error) {
        return error;
      }

      let sessionData = postListResponse.data.data;
      if (!this.options.data.globalSettings.includeVideos.value) {
        sessionData = sessionData.filter(
          (x) =>
            x.media_type === MEDIA_TYPE.IMAGE ||
            x.media_type === MEDIA_TYPE.CAROUSEL_ALBUM
        );
      }

      const sessionKey = getSessionDataPrefix(
        this.options.data.mode,
        this.options.data.company_id,
        this.options.data.application_id,
        CONFIGURATION_TYPE.POST_VIDEO
      );
      // get the Session data prefix to be used to store data
      window.sessionStorage.setItem(sessionKey, JSON.stringify(sessionData));
      window.sessionStorage.setItem(
        "nextUrl",
        JSON.stringify(this.nextUrl || "nextUrl")
      );
    },
  },
  async mounted() {
    // set data on session storage 100 saved on session
    await this.getInstagramData();

    this.syncSession = syncSessionWithState();
    this.syncSession(
      this.nextUrl,
      this.instagramData,
      this.limit,
      this.getInstagramData,
      this.setInstagramData,
      this.setIsNextAvaiable,
      this.options.data.mode,
      this.options.data.company_id,
      this.options.data.application_id,
      CONFIGURATION_TYPE.POST_VIDEO
    );
  },
};
</script>

<style scoped>
.dis-flex {
  display: flex;
}

.hrz-center {
  justify-content: center;
}

.grid-container {
  display: grid;
  grid-template-columns: 2fr 1fr;
}

.parent-grid-container {
  display: flex;
  flex-direction: column;
}

.grid-of-one{
  display: grid;
}
.grid-of-two {
  display: grid;
  grid-template-columns: auto;
  grid-template-rows: 1fr 1fr;
}
</style>
