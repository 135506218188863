<template>
  <div class="social-card">
    <div class="social-cover-image">
      <div 
        v-if="isMobileComponent"
        @click="handleOnClickAction(item, index)"
        class="social-card-image-mobile"
      >
        <SingleMediaElement :mediaType="item.media_type" :imageObject="item" />
      </div>

      <ImageWrapper
        v-else
        :hoverEffects="hoverEffects"
        :caption="item.caption"
        @onClickPopup="handleOnClickAction(item, index)"
        :customStyle="{ height: '100%' }"
      >
        <SingleMediaElement :mediaType="item.media_type" :imageObject="item" />
      </ImageWrapper>
    </div>
    <div class="title">
      <div class="social-dp">
        <img :src="instagramIcon" alt="social-dp-img" class="social-dp-img" />
      </div>
      <p>{{ trimCaption }}</p>
    </div>
  </div>
</template>

<script>
import ImageWrapper from "./image-wrapper.vue";
import instagramIcon from "../assets/icon/social_card_profile.svg";
import SingleMediaElement from "./single-media-element.vue";
import { MODE } from '../services/ultils';

export default {
  name: "social-card",
  title: "Social Card",
  components: { ImageWrapper, SingleMediaElement },
  data() {
    return {
      instagramIcon: instagramIcon,
    };
  },
  props: {
    item: {
      default: {},
    },
    index: {
      default: 0,
    },
    hoverEffects: {
      default: {},
    },
    socialDesc: {
      default: "",
    },
    mode:{
      default:MODE[0]
    },
    isMobile:{
      default:false
    }
  },
  computed: {
    trimCaption() {
      const captionLimit = this.mode === MODE[0] && !this.isMobileComponent ? 100 : 50;
      if (this.item.caption && this.item.caption.length > captionLimit)
        return this.item.caption.slice(0, captionLimit) + "...";
      return this.item.caption;
    },
    isMobileComponent(){
      return this.isMobile;
    }
  },
  methods: {
    handleOnClickAction(currentImageObj, currentImageIndex) {
      this.$emit("handleOnClickAction", currentImageObj, currentImageIndex);
    },
  },
};
</script>

<style scoped lang="scss">
.social-card {
  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.08);
  background: #ffffff;
  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.08);
  height: 500px;

  /* Media queries for preview */
  @media all and (max-width: 767px) {
    height: 350px;
  }

  .social-cover-image {
    height: 70%;
    width: 100%;
    overflow: hidden;

    .social-card-image-mobile{
      display: grid;
      height: 100%;
    }
  }

  .title {
    height: 30%;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    .social-dp {
      position: absolute;
      top: -25px;
      left: 50%;
      padding: 12px;
      border-radius: 50%;
      background: #ffffff;
      box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.08);
      transform: translateX(-50%);
      display: flex;
      justify-content: center;
      align-content: center;
      .social-dp-img {
        height: 24px;
        width: 24px;
        display: block;
      }
    }

    p {
      font-family: unset;
      color: var(--textBody, #5c5c5c);
      font-weight: 400;
      font-size: 14px;
      line-height: 160%;
      margin: 0 10px;
      text-align: center;
      word-break: break-word;
      margin-top: 8px;
      /* Media queries for preview */
      @media all and (max-width: 767px) {
        font-size: 12px;
      }
    }
  }
}
</style>
