<template>
  <div class="video-container">
    <video :src="mediaURL" playsinline autoplay controls muted></video>
  </div>
</template>

<script>
export default {
  name: "video-component",
  props: {
    mediaUrl: { default: "" },
  },
  data() {
    return {
      mediaURL: this.mediaUrl,
    };
  },
  watch: {
    mediaUrl(newMediaUrl) {
      this.mediaURL = newMediaUrl;
    },
  },
};
</script>

<style lang="scss" scoped>
.video-container {
  width: 100%;
  height: 100%;
  video {
    width: 100%;
    height: 100%;
    object-fit: cover;

    // @media all and (max-width: 420px) {
    //   height: auto;
    // }
  }
}
</style>
