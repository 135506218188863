<template>
  <div v-if="instagramData.length > 0" class="slider-container-instagram-feed" :data-testid="dataTestIdComputed">
    <!-- SLICK CAROUSAL -->
    <VueSlickCarousel
      v-bind="settings"
      class="carousel"
      ref="carousel"
      @beforeChange="beforeChange"
      @swipe="onSwipe"
    >
      <!-- EACH SLIDE -->
      <div
        :style="{ marginBottom: getSpacing + 'px' }"
        v-for="(item, index) in groupImages"
        :key="index"
        class="slide"
      >
        <!-- EACH GRID -->
        <div
          class="grid-container"
          :style="{
            gap: getSpacing + 'px',
            gridTemplateColumns: `repeat(4, 1fr)`,
            gridTemplateRows: `repeat(1, 1fr)`,
          }"
        >
          <div
            v-for="(image, index) in item"
            :key="index"
            class="pos-rel"
            @click="handleOnClickAction(image, image.actualIndex)"
          >
            <img :src="reelPlay" class="reel-play-overlay" alt="" />
            <video
              loop
              class="video-item"
              :src="image.media_url"
              autoplay
              muted
              playsinline
              webkit-playsinline="true"
            ></video>
          </div>
        </div>
      </div>
    </VueSlickCarousel>

    <button
      :class="noPrev ? 'd-none' : 'slick-arrow slick-prev'"
      @click="showprev"
    >
      <img :src="prevBtnPng" class="next-btn-png" />
    </button>

    <button
      :class="noNext ? 'd-none' : 'slick-arrow slick-next'"
      @click="showNext"
    >
      <img :src="nextBtnPng" class="next-btn-png" />
    </button>

    <modalContainer ref="modal">
      <template>
        <modalContent
          :currentImageObject="imageObject"
          :modalCurrentIndex="imageIndex"
          :allImages="this.instagramData"
          :mode="this.options.data.mode"
          :application_id="this.options.data.application_id"
          :company_id="this.options.data.company_id"
          @prevImage="previtem($event)"
          @nextImage="nextitem($event)"
          @handleCloseModal="handleCloseModal()"
        />
      </template>
    </modalContainer>
  </div>
</template>

<script>
import VueSlickCarousel from "vue-slick-carousel";
import "vue-slick-carousel/dist/vue-slick-carousel.css";
import "vue-slick-carousel/dist/vue-slick-carousel-theme.css";
import modalContainer from "../../modal/modal-container.vue";
import modalContent from "../../modal/modal-content.vue";
import {
  getSessionDataPrefix,
  syncSessionWithState,
} from "../../../services/helper";
import InstagramService from "../../../services/instagram.service";
import reelPlay from "../../../assets/icon/reel_play.png";
import {
  CLICK_SETTING_REELS,
  CONFIGURATION_TYPE,
  MEDIA_TYPE,
  SESSION_DATA_LIMIT_DESKTOP,
  SWIPE_DIRECTION,
} from "../../../services/ultils";
import nextBtnPng from "../../../assets/icon/arrow_next.png";
import prevBtnPng from "../../../assets/icon/arrow_prev.png";
import { UNIQUE_IDENTIFIER_TYPES, generateDataTestId } from "../../../constant/testingConstants";

export default {
  name: "reel-arrangemnt",
  title: "Reel Arrangement",
  props: {
    options: { default: {} },
    dataTestId:{ default : null}
  },
  components: {
    modalContainer,
    modalContent,
    VueSlickCarousel,
  },

  computed: {
    getSpacing() {
      return this.options.data.globalSettings.spacing.value;
    },
    groupImages() {
      const result = [];
      let currentGroup = [{ ...this.instagramData[0], actualIndex: 0 }];
      let moduloLimit = this.limit / 2;

      for (let i = 1; i < this.instagramData.length; i++) {
        if (i % moduloLimit == 0) {
          result.push(currentGroup);
          currentGroup = [{ ...this.instagramData[i], actualIndex: i }];
        } else {
          currentGroup.push({ ...this.instagramData[i], actualIndex: i });
        }
      }
      result.push(currentGroup);
      return result;
    },
    dataTestIdComputed(){
      return generateDataTestId(this.dataTestId,`${UNIQUE_IDENTIFIER_TYPES.DESKTOP}_${UNIQUE_IDENTIFIER_TYPES.CAROUSAL}`);
    }
  },

  data() {
    return {
      prevBtnPng,
      nextBtnPng,
      settings: {
        lazyLoad: "ondemand",
        slidesToShow: 1,
        slidesToScroll: 1,
        infinite: false,
        swipeToSlide: true,
        arrows: false,
      },
      imageObject: null,
      imageIndex: 0,
      limit: 8,
      isNextAvaiable: true,
      instagramData: [],
      noPrev: true,
      noNext: false,
      reelPlay: reelPlay,
      syncSession: null,
    };
  },
  methods: {
    beforeChange(oldSlideIndex, newSlideIndex) {
      if (newSlideIndex === 0) {
        this.noPrev = true;
      } else {
        this.noPrev = false;
      }
      if (this.groupImages.length - 1 === newSlideIndex) {
        this.noNext = true;
      } else {
        this.noNext = false;
      }
    },
    onSwipe(direction) {
      if (direction === SWIPE_DIRECTION.LEFT) this.handleOnNext();
    },
    showprev() {
      if (!this.noPrev) {
        this.$refs.carousel.prev();
      }
    },
    showNext() {
      if (!this.noNext) {
        this.handleOnNext();
        this.$refs.carousel.next();
      }
    },
    handleOnClickAction(currentImageObj, currentImageIndex) {
      const onClickValue =
        this.options.data.onClickActions.onClickConfiguration.value;
      if (onClickValue === CLICK_SETTING_REELS.OPEN_IN_MODAL_WINDOW) {
        this.$refs.modal.openModal();
        this.imageObject = currentImageObj;
        this.imageIndex = currentImageIndex;
      } else if (onClickValue === CLICK_SETTING_REELS.SEE_ON_INSTAGRAM) {
        window.open(currentImageObj?.permalink, "_blank");
      }
    },
    previtem() {
      if (this.imageIndex > 0) {
        this.imageIndex -= 1;
      }
      if (this.instagramData[this.imageIndex]) {
        this.imageObject = this.instagramData[this.imageIndex];
      }
    },
    nextitem() {
      if (this.imageIndex >= this.instagramData.length) {
        this.imageIndex = this.instagramData.length;
      } else {
        this.imageIndex += 1;
      }
      if (this.instagramData[this.imageIndex]) {
        this.imageObject = this.instagramData[this.imageIndex];
      }
    },
    handleCloseModal() {
      this.$refs.modal.closeModal();
    },
    setInstagramData(value) {
      this.instagramData = value;
    },
    setIsNextAvaiable(value) {
      this.isNextAvaiable = value;
    },
    handleOnNext() {
      if (this.isNextAvaiable) {
        this.syncSession(
          this.nextUrl,
          this.instagramData,
          this.limit,
          this.getInstagramData,
          this.setInstagramData,
          this.setIsNextAvaiable,
          this.options.data.mode,
          this.options.data.company_id,
          this.options.data.application_id,
          CONFIGURATION_TYPE.REELS
        );
      }
    },

    async getInstagramData(nextUrl) {
      let postListResponse;
      try {
        if (nextUrl) {
          console.log("get next data from url in params");
        } else {
          postListResponse = await InstagramService.getPostList(
            SESSION_DATA_LIMIT_DESKTOP,
            this.options.data.mode,
            this.options.data.company_id,
            this.options.data.application_id
          );
        }
      } catch (error) {
        return error;
      }
      //
      let reelsOnly = postListResponse.data.data.filter(
        (x) => x.media_type === MEDIA_TYPE.VIDEO
      );
      const sessionKey = getSessionDataPrefix(
        this.options.data.mode,
        this.options.data.company_id,
        this.options.data.application_id,
        CONFIGURATION_TYPE.REELS
      );
      // get the Session data prefix to be used to store data
      window.sessionStorage.setItem(sessionKey, JSON.stringify(reelsOnly));

      window.sessionStorage.setItem(
        "nextUrl",
        JSON.stringify(this.nextUrl || "nextUrl")
      );
    },
  },
  async mounted() {
    await this.getInstagramData();
    this.syncSession = syncSessionWithState();
    this.syncSession(
      this.nextUrl,
      this.instagramData,
      this.limit,
      this.getInstagramData,
      this.setInstagramData,
      this.setIsNextAvaiable,
      this.options.data.mode,
      this.options.data.company_id,
      this.options.data.application_id,
      CONFIGURATION_TYPE.REELS
    );
  },
};
</script>

<style scoped>
.slider-container-instagram-feed {
  position: relative;
}

.grid-container {
  display: grid;
}

.dis-flex {
  display: flex;
}

.hrz-center {
  justify-content: center;
}

.video-item {
  width: 100%;
  height: 100%;
  object-fit: cover;
  cursor: pointer;
}

.pos-rel {
  position: relative;
  height: 500px;
}
.pos-rel .reel-play-overlay {
  display: none;
}

.pos-rel:hover .reel-play-overlay {
  display: block;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 40px;
  height: 40px;
}

.d-none {
  display: none;
}
.slick-next {
  right: -4px !important;
  top: 50% !important;
}

.slick-prev {
  left: -14px !important;
  top: 50% !important;
}

.slick-next:before,
.slick-prev:before {
  font-family: slick;
  font-size: 20px;
  line-height: 1;
  opacity: 0.75;
  color: rgba(0, 0, 0, 0.656) !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  display: none;
}

.slick-next.slick-disabled:before,
.slick-prev.slick-disabled:before {
  color: #888 !important;
}

.next-btn-png {
  width: 2rem;
  height: 2rem;
}

@media all and (max-width: 767px) {
  .pos-rel {
    height: 300px;
  }
}
</style>
