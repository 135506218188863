import axios from "axios";
let { url } = require("./urlset");
import {
  RESPONSE_HEADERS,
  getMediaListEndpoint,
  getMediaListItemEndpoint,
} from "./ultils";

// AXIOS interceptor for storefont to get companyid and application id from response of API
let count = 0;

const ifAxios = axios.create({});

ifAxios.interceptors.response.use(
  function (response) {
    // Set the company id and application id for the first time in localstorage
    // These will be used only in case of storefront
    if (count == 0) {
      localStorage.setItem(
        RESPONSE_HEADERS.COMPANY_ID,
        `${response?.headers[RESPONSE_HEADERS.COMPANY_ID]}`
      );
      localStorage.setItem(
        RESPONSE_HEADERS.APPLICATION_ID,
        `${response?.headers[RESPONSE_HEADERS.APPLICATION_ID]}`
      );
    }
    return response;
  },
  function (error) {
    return error;
  }
);

const MainServices = {
  getPostList(limit, mode, company_id = null, application_id = null) {
    let apiEndPoint = getMediaListEndpoint(mode, application_id);
    apiEndPoint = `${apiEndPoint}?limit=${limit}`;

    return ifAxios.get(apiEndPoint, {
      headers: {
        "x-company-id": company_id,
      },
    });
  },

  getImageListChildren(itemId, mode, company_id = null, application_id = null) {
    let apiEndPoint = getMediaListItemEndpoint(mode, itemId, application_id);
    return ifAxios.get(apiEndPoint, {
      headers: {
        "x-company-id": company_id,
      },
    });
  },

  // this method will be used only for storefront , not for preview
  getConfiguration() {
    return ifAxios.get(`${url}/ext/instagram-feed/api/v2/app-config`);
  },
};
export default MainServices;
