<template>
  <div :data-testid="dataTestIdComputed">
    <MainHeading
      :allignmentType="allignmentType"
      :value="getHeading"
      :show="showHeading"
      :dataTestId="dataTestIdComputed"
    />
    <SubHeading
      :allignmentType="allignmentType"
      :value="getSubHeading"
      :show="showSubHeading"
      :dataTestId="dataTestIdComputed"
    />
    <component v-bind:is="getCorrectResponsiveLayout" :options="options" :dataTestId="dataTestIdComputed"/>
  </div>
</template>

<script>
import GalleryArrangement from "./carousel/gallery-arrangement.vue";
import GridArrangement from "./grid/grid-gallery-arrangement.vue";
import GridGalleryArrangementResponsive from "./grid/grid-gallery-arrangement-responsive.vue";
import GalleryArrangementResponsive from "./carousel/gallery-arrangement-responsive.vue";
import SubHeading from "../sub-heading.vue";
import MainHeading from "../main-heading.vue";
import { UNIQUE_IDENTIFIER_TYPES, generateDataTestId } from '../../constant/testingConstants';

export default {
  name: "gallery-arrangement-main",
  title: "Gallery arrangement main",
  props: {
    options: { default: {} },
    dataTestId:{ default : null}
  },
  components: {
    GalleryArrangement,
    GridArrangement,
    GridGalleryArrangementResponsive,
    GalleryArrangementResponsive,
    SubHeading,
    MainHeading,
  },
  data() {
    return {
      component: {
        GRID: "GridArrangement",
        CAROUSEL: "GalleryArrangement",
      },
      componentResponsive: {
        GRID: "GridGalleryArrangementResponsive",
        CAROUSEL: "GalleryArrangementResponsive",
      },
    };
  },
  computed: {
    allignmentType() {
      return this.options.data.globalSettings.title.allignment.value;
    },
    showHeading() {
      return this.options.data.globalSettings.title.heading.show;
    },
    showSubHeading() {
      return (
        this.showHeading &&
        this.options.data.globalSettings.title.subHeading.show
      );
    },
    getHeading() {
      return this.options.data.globalSettings.title.heading.content;
    },
    getSubHeading() {
      return this.options.data.globalSettings.title.subHeading.content;
    },
    getActiveLayout() {
      return this.options.data.globalSettings.layoutTemplate.value;
    },
    getCorrectResponsiveLayout() {
      if (this.options.data.isMobile)
        return this.componentResponsive[this.getActiveLayout];
      return this.component[this.getActiveLayout];
    },
    dataTestIdComputed(){
      return generateDataTestId(this.dataTestId,`Gallery_${UNIQUE_IDENTIFIER_TYPES.ARRANGEMENT}`);
    }
  },
};
</script>

<style scoped></style>
