<template>
  <div
    v-if="isNextAvaiable"
    class="load-more-btn"
    @click.self="handleLoadMore()"
  >
    Load More
  </div>
</template>

<script>
export default {
  props: {
    isNextAvaiable: { default: false },
    handleClick: { default: null },
  },
  methods: {
    handleLoadMore() {
      this.$emit("handleClick");
    },
  },
};
</script>

<style scoped>
.load-more-btn {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 129px;
  height: 48px;
  padding: 6px 12px;
  margin-top: 1.2rem;
  cursor: pointer;
  text-transform: uppercase;

  /* font styling to be fetched from storefront */
  /* fallback color as present in preview */
  font-family: unset;
  color: var(--buttonPrimary, #2e31be);
  border-radius: var(--buttonRadius, 4px);
  border: 1px solid var(--buttonPrimary, #2e31be);

  /* Using Storefront font as per design */
  font-weight: 500;
  font-size: 14px;
}

/* Media queries for preview */
@media all and (max-width: 767px) {
  .load-more-btn {
    width: auto;
    height: 30px;
  }
}
</style>
